import React from "react";

const RequiredLabel = ({ children, required = true }) => {
  return (
    <>
      {children} {required && <span className="text-danger">*</span>}
    </>
  );
};

export default RequiredLabel;
