import React from "react";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import "./DisciplineAccordion.css";

const DisciplineAccordion = ({ sg, isOpen, onToggle, children, onRemove }) => {
  return (
    <div
      id="discipline-accordion"
      className={`custom-accordion-item ${isOpen ? "open" : ""}`}
    >
      <div className="custom-accordion-header" onClick={onToggle}>
        <span className="sportgren-title">{sg.sportgren}</span>
        <div className="accordion-controls">
          <MDBIcon icon={isOpen ? "chevron-up" : "chevron-down"} />
          <MDBBtn
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <MDBIcon icon="trash-alt" />
          </MDBBtn>
        </div>
      </div>
      {isOpen && <div className="custom-accordion-body">{children}</div>}
    </div>
  );
};

export default DisciplineAccordion;
