import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import LovForeningar from "components/app/lov/LovForeningar";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step5_Organizers = ({
  formData,
  setFormData,
  stepTitle,
  stepValidity,
  currentStep,
}) => {
  const addArrangor = (childData) => {
    const arr = [...formData.arrangorer];
    arr.push({
      forening_id: childData.value,
      name: childData.text,
    });
    setFormData({ ...formData, arrangorer: arr });
  };

  const removeArrangor = (index) => {
    const updated = [...formData.arrangorer];
    updated.splice(index, 1);
    setFormData({ ...formData, arrangorer: updated });
  };

  const showValidationError =
    stepValidity?.[currentStep] === false && formData.arrangorer.length === 0;

  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={12} />
        <LovForeningar parentCallback={addArrangor} />
      </MDBCol>

      <MDBCol md="9">
        {formData.arrangorer.length === 0 ? (
          <p className="text-muted fst-italic">Ingen arrangör tillagd ännu.</p>
        ) : (
          <div className="scrollable-list">
            <ul className="list-group mb-4">
              {formData.arrangorer.map((arr, i) => (
                <li
                  key={i}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {arr.name}
                  <MDBBtn
                    type="button"
                    size="sm"
                    color="danger"
                    onClick={() => removeArrangor(i)}
                  >
                    <MDBIcon icon="trash-alt" />
                  </MDBBtn>
                </li>
              ))}
            </ul>
          </div>
        )}

        {showValidationError && (
          <div className="text-danger mt-n2 mb-3">
            Minst en arrangör måste anges.
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default Step5_Organizers;
