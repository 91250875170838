import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";

export default function ListLicSportgrenMore({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab9") {
      fetch_json_full_url("https://www.skytteta.se/api/data/lic_sportgren_more")
        .then((response) => {
            const numericData = response.data.map((item) => ({
            ...item,
            "dec-24": Number(item["dec-24"]),
            "dec-24-kr": Number(item["dec-24-kr"]),
            "jan": Number(item["jan"]),
            "jan-kr": Number(item["jan-kr"]),
            "feb": Number(item["feb"]),
            "feb-kr": Number(item["feb-kr"]),
            "mar": Number(item["mar"]),
            "mar-kr": Number(item["mar-kr"]),
            "apr": Number(item["apr"]),
            "apr-kr": Number(item["apr-kr"]),
            "maj": Number(item["maj"]),
            "maj-kr": Number(item["maj-kr"]),
            "jun": Number(item["jun"]),
            "jun-kr": Number(item["jun-kr"]),
            "jul": Number(item["jul"]),
            "jul-kr": Number(item["jul-kr"]),
            "aug": Number(item["aug"]),
            "aug-kr": Number(item["aug-kr"]),
            "sep": Number(item["sep"]),
            "sep-kr": Number(item["sep-kr"]),
            "okt": Number(item["okt"]),
            "okt-kr": Number(item["okt-kr"]),
            "nov": Number(item["nov"]),
            "nov-kr": Number(item["nov-kr"]),
            "dec": Number(item["dec"]),
            "dec-kr": Number(item["dec-kr"]),
            "totalt": Number(item["totalt"]),
            "totalt_kr": Number(item["totalt_kr"]),
            "totalt_m": Number(item["totalt_m"]),
            "procent_m": Number(item["procent_m"]),
            "totalt_k": Number(item["totalt_k"]),
            "procent_k": Number(item["procent_k"]),
            "totalt_senior": Number(item["totalt_senior"]),
            "procent_senior": Number(item["procent_senior"]),
            "totalt_junior": Number(item["totalt_junior"]),
            "procent_junior": Number(item["procent_junior"]),
            "totalt_m_senior": Number(item["totalt_m_senior"]),
            "procent_m_senior": Number(item["procent_m_senior"]),
            "totalt_m_junior": Number(item["totalt_m_junior"]),
            "procent_m_junior": Number(item["procent_m_junior"]),
            "totalt_k_senior": Number(item["totalt_k_senior"]),
            "procent_k_senior": Number(item["procent_k_senior"]),
            "totalt_k_junior": Number(item["totalt_k_junior"]),
            "procent_k_junior": Number(item["procent_k_junior"]),
            }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const formattedData = data.map((item) => ({
      "Sportgren Name": item.sportgren_name,
      "Dec 24": item["dec-24"],
      "Dec 24 Kr": item["dec-24-kr"],
      Jan: item.jan,
      "Jan Kr": item["jan-kr"],
      Feb: item.feb,
      "Feb Kr": item["feb-kr"],
      Mar: item.mar,
      "Mar Kr": item["mar-kr"],
      Apr: item.apr,
      "Apr Kr": item["apr-kr"],
      Maj: item.maj,
      "Maj Kr": item["maj-kr"],
      Jun: item.jun,
      "Jun Kr": item["jun-kr"],
      Jul: item.jul,
      "Jul Kr": item["jul-kr"],
      Aug: item.aug,
      "Aug Kr": item["aug-kr"],
      Sep: item.sep,
      "Sep Kr": item["sep-kr"],
      Okt: item.okt,
      "Okt Kr": item["okt-kr"],
      Nov: item.nov,
      "Nov Kr": item["nov-kr"],
      Dec: item.dec,
      "Dec Kr": item["dec-kr"],
      Totalt: item.totalt,
      "Totalt Kr": item.totalt_kr,
      "Totalt M": item.totalt_m,
      "Procent M": item.procent_m,
      "Totalt K": item.totalt_k,
      "Procent K": item.procent_k,
      "Totalt Senior": item.totalt_senior,
      "Procent Senior": item.procent_senior,
      "Totalt Junior": item.totalt_junior,
      "Procent Junior": item.procent_junior,
      "Totalt M Senior": item.totalt_m_senior,
      "Procent M Senior": item.procent_m_senior,
      "Totalt M Junior": item.totalt_m_junior,
      "Procent M Junior": item.procent_m_junior,
      "Totalt K Senior": item.totalt_k_senior,
      "Procent K Senior": item.procent_k_senior,
      "Totalt K Junior": item.totalt_k_junior,
      "Procent K Junior": item.procent_k_junior,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel format

    // Set column widths
    const columnWidths = formattedData.reduce((widths, row) => {
      Object.keys(row).forEach((key, index) => {
        const value = row[key] ? row[key].toString().length : 10;
        widths[index] = Math.max(widths[index] || 10, value);
      });
      return widths;
    }, []);

    worksheet['!cols'] = columnWidths.map(width => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Add to workbook
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Create and download file
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: "sportgren_name",
        header: "Sportgren Name",
        size: 150,
        muiTableHeadCellProps: { sx: { fontWeight: "bold" } },
      },
      {
        accessorKey: "dec-24",
        header: "Dec 24",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec-24-kr",
        header: "Dec 24 Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan",
        header: "Jan",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan-kr",
        header: "Jan Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb",
        header: "Feb",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb-kr",
        header: "Feb Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar",
        header: "Mar",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar-kr",
        header: "Mar Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr",
        header: "Apr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr-kr",
        header: "Apr Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj",
        header: "Maj",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj-kr",
        header: "Maj Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun",
        header: "Jun",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun-kr",
        header: "Jun Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul",
        header: "Jul",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul-kr",
        header: "Jul Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug",
        header: "Aug",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug-kr",
        header: "Aug Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep",
        header: "Sep",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep-kr",
        header: "Sep Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt",
        header: "Okt",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt-kr",
        header: "Okt Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov",
        header: "Nov",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov-kr",
        header: "Nov Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec",
        header: "Dec",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec-kr",
        header: "Dec Kr",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt",
        header: "Totalt",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_kr",
        header: "Totalt Kr",
        size: 100,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_m",
        header: "Totalt M",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_m",
        header: "Procent M",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_k",
        header: "Totalt K",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_k",
        header: "Procent K",
        size: 80,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_senior",
        header: "Totalt Senior",
        size: 100,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_senior",
        header: "Procent Senior",
        size: 100,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_junior",
        header: "Totalt Junior",
        size: 100,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_junior",
        header: "Procent Junior",
        size: 100,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_m_senior",
        header: "Totalt M Senior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_m_senior",
        header: "Procent M Senior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_m_junior",
        header: "Totalt M Junior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_m_junior",
        header: "Procent M Junior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_k_senior",
        header: "Totalt K Senior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_k_senior",
        header: "Procent K Senior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_k_junior",
        header: "Totalt K Junior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_k_junior",
        header: "Procent K Junior",
        size: 120,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    // layoutMode: "grid", //"semantic", //grid-no-grow",
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
    }, // Sätter standardantal rader per sida
    renderTopToolbar: () => {
      return (
        <button
          onClick={handleExport}
          style={{ padding: "8px 16px", cursor: "pointer" }}
        >
          Exportera till Excel
        </button>
      );
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx:
        row.original.lic_typ === "*** Totalt ***"
          ? { backgroundColor: "#f0f0f0", fontWeight: "bold", color: "white" }
          : {},
    }),
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
