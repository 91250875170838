import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";
//dhdhd
export default function Payex_grp({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab4") {
      fetch_json_full_url("https://www.skytteta.se/api/data/payex_grp?_orderby=AccountingNo desc")
        .then((response) => {
          const numericData = response.data.map((item) => ({
            ...item,
            TotalNoOfTransactions: Number(item.TotalNoOfTransactions),
            fee: Number(item.fee),
            vat: Number(item.vat),
            amount: Number(item.amount),
            qty: Number(item.qty),
            total: Number(item.total),
          }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(data); // Konvertera data till Excel-format

    // Sätt kolumnbredd
    const columnWidths = [
      { wch: 20 }, // AccountingNo
      { wch: 30 }, // ExternalMerchantId
      { wch: 20 }, // fee
      { wch: 10 }, // vat
      { wch: 15 }, // amount
      { wch: 10 }, // qty
      { wch: 20 }, // total
    ];

    // Ange kolumnformat för specifika kolumner
    const columnFormats = {
      B: "0", // TotalNoOfTransactions som tal
      E: "0.00", // fee som tal med två decimaler
      F: "0.00", // vat som tal med två decimaler
      G: "0.00", // amount som tal med två decimaler
      H: "0", // qty som tal
      I: "0.00", // total som tal med två decimaler
    };

    Object.keys(columnFormats).forEach((key) => {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const cellAddress = `${key}${rowNum + 1}`;
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].z = columnFormats[key];
        }
      }
    });

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Lägg till i arbetsboken
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Skapa och ladda ner filen
  };


  const tableColumns = useMemo(
    () => [
      { accessorKey: "AccountingNo", header: "PayexNo", size: 100 },
      // { accessorKey: "TotalNoOfTransactions", header: "TotalNoOfTransactions kr", size: 60 },
      {
        accessorKey: "ExternalMerchantId",
        header: "ExternalMerchantId",
        size: 100,
      },
      {
        accessorKey: "fee",
        header: "fee",
        size: 100,
        Cell: ({ cell }) => cell.getValue().toFixed(2),
        muiTableBodyCellProps: { align: "right"  },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "vat",
        header: "vat",
        size: 100,
        Cell: ({ cell }) => cell.getValue().toFixed(2),
        muiTableBodyCellProps: { align: "right"  },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "amount",
        header: "amount ID",
        size: 100,
        Cell: ({ cell }) => cell.getValue().toFixed(2),
        muiTableBodyCellProps: { align: "right"  },
        muiTableHeadCellProps: { align: "right" },
        // Cell: ({ cell }) => (
        //   <>
        //     {cell.getValue()?.toLocaleString?.('en-SV', {
        //       style: 'currency',
        //       currency: 'SEK',
        //       minimumFractionDigits: 0,
        //       maximumFractionDigits: 0,

        //     })}

        //   </>

        // ),
      },
      {
        accessorKey: "qty",
        header: "qty",
        size: 100,
        Cell: ({ cell }) => cell.getValue().toFixed(0),
        muiTableBodyCellProps: { align: "right"  },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "total",
        header: "total",
        size: 100,
        Cell: ({ cell }) => cell.getValue().toFixed(2),
        muiTableBodyCellProps: { align: "right"  },
        muiTableHeadCellProps: { align: "right" },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    // layoutMode: "grid", //"semantic", //grid-no-grow",
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
    }, // Sätter standardantal rader per sida
    renderTopToolbar: () => {
      return (
        <button
          onClick={handleExport}
          style={{ padding: "8px 16px", cursor: "pointer" }}
        >
          Exportera till Excel
        </button>
      );
    },
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
