import React from "react";
import { MDBRow, MDBCol, MDBInput, MDBValidationItem } from "mdb-react-ui-kit";
import RequiredLabel from "components/common/RequiredLabel";
import "../EventWizard.css";
import RequiredFieldsNote from "components/common/RequiredFieldsNote";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step1_AboutEvent = ({
  formData,
  onChange,
  lovTypes,
  lovKategorier,
  stepTitle,
}) => {
  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={8} />
        <RequiredFieldsNote />
        <MDBValidationItem className="mb-3" invalid feedback="Ange ett namn.">
          <MDBInput
            name="name"
            label={<RequiredLabel>Namn på tävling</RequiredLabel>}
            required
            value={formData.name}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem className="mb-3" invalid feedback="Ange plats.">
          <MDBInput
            name="plats"
            label={<RequiredLabel>Plats</RequiredLabel>}
            required
            value={formData.plats}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem
          className="mb-3"
          invalid
          feedback="Välj typ av tävling"
        >
          <RequiredLabel>Typ av tävling</RequiredLabel>
          <select
            className="form-select"
            name="event_type_id"
            value={formData.event_type_id}
            onChange={onChange}
            required
          >
            <option value="">Välj...</option>
            {lovTypes.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>
            ))}
          </select>
        </MDBValidationItem>

        <MDBValidationItem className="mb-3" invalid feedback="Välj kategori">
          <RequiredLabel>Kategori</RequiredLabel>
          <select
            className="form-select"
            name="kategori_id"
            value={formData.kategori_id}
            onChange={onChange}
            required
          >
            <option value="">Välj...</option>
            {lovKategorier.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>
            ))}
          </select>
        </MDBValidationItem>
      </MDBCol>
    </MDBRow>
  );
};

export default Step1_AboutEvent;
