import React from "react";
import { MDBRow, MDBCol, MDBValidationItem } from "mdb-react-ui-kit";
import DatePicker from "components/common/DatePicker";
import RequiredLabel from "components/common/RequiredLabel";
import RequiredFieldsNote from "components/common/RequiredFieldsNote";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const dateFields = [
  {
    key: "start_date",
    label: "Startdatum",
    feedback: "Ange startdatum.",
    required: true,
  },
  {
    key: "end_date",
    label: "Slutdatum",
    feedback: "Ange slutdatum.",
    required: true,
  },
];

const Step2_Dates = ({ formData, onChangeDate, stepTitle }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={9} />
        <RequiredFieldsNote />

        {dateFields.map(({ key, label, feedback, required }) => {
          const isEndDate = key === "end_date";

          const min =
            isEndDate && formData.start_date
              ? new Date(new Date(formData.start_date).getTime() - 86400000)
              : yesterday;

          return (
            <MDBValidationItem
              key={key}
              className="mb-3"
              invalid
              feedback={feedback}
            >
              <DatePicker
                inputLabel={
                  required ? <RequiredLabel>{label}</RequiredLabel> : label
                }
                required={required}
                value={formData[key]}
                min={min}
                onChange={(val) => onChangeDate(key, val)}
              />
            </MDBValidationItem>
          );
        })}
      </MDBCol>
    </MDBRow>
  );
};

export default Step2_Dates;
