import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { fetch_json_full_url } from "services/common";

export default function Ta_Payex({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [detailedData, setDetailedData] = useState([]); // Ny state-variabel för detaljerad data

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (activeTab === "tab5") {
      fetch_json_full_url("https://www.skytteta.se/api/data/ta_payex")
        .then((response) => {
          const numericData = response.data.map((item) => ({
            ...item,
            ta_kr: Number(item.ta_kr),
            dev_kr: Number(item.dev_kr),
            payex_kr: Number(item.payex_kr),
            diff_ta_payex: Number(item.diff_ta_payex),
            ta_man_kr: Number(item.ta_man_kr),
            ta_total_kr: Number(item.ta_total_kr),
            qty: Number(item.qty),
          }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const selectedRows =
      Object.keys(rowSelection).length === 0
        ? data
        : Object.keys(rowSelection).map((key) => data[key]);
    const formattedData = selectedRows.map((item) => ({
      "Accounting Date": item.AccountingDate || "Ej från PayEx",
      "TA KR": item.ta_kr,
      "DEV KR": item.dev_kr,
      "PayEx KR": item.payex_kr !== null ? item.payex_kr : "Ej från PayEx",
      "Diff TA PayEx":
        item.diff_ta_payex !== null ? item.diff_ta_payex : "Ej från PayEx",
      "PayEx Accounting No": item.Payex_accountingNo || "Ej från PayEx",
      Antal: item.qty || " - ",
      "TA Man KR": item.ta_man_kr,
      "TA Total KR": item.ta_total_kr,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Konvertera data till Excel-format

    // Sätt kolumnbredd
    const columnWidths = [
      { wch: 20 }, // Accounting Date
      { wch: 10 }, // TA KR
      { wch: 10 }, // DEV KR
      { wch: 10 }, // PayEx KR
      { wch: 15 }, // Diff TA PayEx
      { wch: 20 }, // PayEx Accounting No
      { wch: 10 }, // Qty
      { wch: 10 }, // TA Man KR
      { wch: 15 }, // TA Total KR
    ];

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Lägg till i arbetsboken
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Skapa och ladda ner filen
  };

  // Funktion för att exportera detaljerad data till Excel
  const handleExportDetail = async () => {
    try {
      const response = await fetch_json_full_url(
        "https://www.skytteta.se/api/data/ta_payex_detail"
      );
      const detailedData = response.data.map((item) => ({
        ...item,
        amount: Number(item.amount),
      }));
      setDetailedData(detailedData);
      console.log("rowSelection", rowSelection);
      const selectedRows =
        Object.keys(rowSelection).length === 0
          ? data
          : Object.keys(rowSelection).map((key) => data[key]);
      console.log("selectedRows", selectedRows);
      const filteredDetailedData = detailedData.filter((detailItem) =>
        selectedRows.some(
          (selectedItem) =>
            selectedItem.AccountingDate === detailItem.AccountingDate ||
            (selectedItem.AccountingDate === "Ej från PayEx" &&
              !detailItem.AccountingDate)
        )
      );

      const formattedData = filteredDetailedData.map((item) => ({
        "Accounting Date": item.AccountingDate,
        "Order ID": item.order_id,
        Amount: item.amount,
        Description: item.description,
        "Add Date": item.add_date,
        "Receiver User ID": item.reciever_user_id,
        // "Receiver Name": item.reciever_name,
        "IID Nr": item.iid_nr,
        // "License ID": item.license_id,
        // "License Status": item.lic_status,
        "Sportgren Name": item.sportgren_name,
        // "Payment Method": item.payment_method,
        // Gender: item.gender,
        // Status: item.status,
        // "Added Manually": item.added_manually,
        // Age: item.age,
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel format

      // Set column widths
      const columnWidths = [
        { wch: 20 }, // Accounting Date
        { wch: 10 }, // Order ID
        { wch: 10 }, // Amount
        { wch: 30 }, // Description
        { wch: 20 }, // Add Date
        { wch: 20 }, // Receiver User ID
        // { wch: 25 }, // Receiver Name
        { wch: 15 }, // IID Nr
        // { wch: 15 }, // License ID
        // { wch: 15 }, // License Status
        { wch: 20 }, // Sportgren Name
        // { wch: 20 }, // Payment Method
        // { wch: 10 }, // Gender
        // { wch: 15 }, // Status
        // { wch: 15 }, // Added Manually
        // { wch: 10 }, // Age
      ];

      worksheet["!cols"] = columnWidths;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Add to workbook
      XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Create and download file
    } catch (error) {
      console.error("Error fetching detailed data:", error);
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: "AccountingDate",
        header: "Accounting Date",
        size: 150,
        filterFn: "startsWith", // Disable fuzzy filtering by using a strict equality filter
        //filterVariant: 'date-range',
        // Cell: ({ cell }) => {
        //   const date = cell.getValue();
        //   return date ? date.replace(/-/g, "") : ""; // Ta bort bindestreck
        // },
      },
      {
        accessorKey: "ta_kr",
        header: "TA KR",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "dev_kr",
        header: "DEV KR",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "payex_kr",
        header: "PayEx KR",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "diff_ta_payex",
        header: "Diff TA PayEx",
        size: 150,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "Payex_accountingNo",
        header: "PayEx Accounting No",
        size: 200,
      },
      {
        accessorKey: "qty",
        header: "Antal",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "ta_man_kr",
        header: "TA Man KR",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      {
        accessorKey: "ta_total_kr",
        header: "TA Total KR",
        size: 150,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: false,
    enableGlobalFilter: false,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableBottomToolbar: false,
    columnFilterDisplayMode: "popover",
    enableHiding: false,
    enableColumnFilters: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableRowSelection: true,
    positionToolbarAlertBanner: "none",
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: true,
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex flex-row mb-3">
        {/* <MDBBtn onClick={() => setBasicModal(true)} size="sm" className="me-2">
          <MDBIcon fas icon="plus" className="me-2" />
          Test
        </MDBBtn> */}
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <div>
          <div>
            <MDBBtn onClick={handleClick} size="sm" color="secondary">
              Export till Excel
              <MDBIcon fas icon="caret-down" className="ps-1" />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem dense onClick={handleExport}>
              <MDBIcon fas icon="file-excel" className="me-2" />
              Enkel lista
            </MenuItem>
            <MenuItem dense onClick={handleExportDetail}>
              <MDBIcon fas icon="file-excel" className="me-2" />
              Detaljerad lista
            </MenuItem>
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag="a"
          size="sm"
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color="info" size="lg" />
        </MDBBtn>
      </>
    ),
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
