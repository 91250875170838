import React from "react";
import { MDBRow, MDBCol, MDBValidationItem } from "mdb-react-ui-kit";
import DatePicker from "components/common/DatePicker";
import RequiredLabel from "components/common/RequiredLabel";
import RequiredFieldsNote from "components/common/RequiredFieldsNote";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const dateFields = [
  {
    key: "publicering_date",
    label: "Publiceringsdatum",
    feedback: "Ange publiceringsdatum.",
    required: false,
  },
  {
    key: "anmalan_start_date",
    label: "Anmälan öppnar",
    feedback: "Ange datum för öppning av anmälan.",
    required: true,
  },
  {
    key: "anmalan_end_date",
    label: "Sista anmälningsdag",
    feedback: "Ange sista anmälningsdatum.",
    required: false,
  },
];

const Step3_ExtraDates = ({ formData, onChangeDate, stepTitle }) => {
  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={10} />
        <RequiredFieldsNote />
        {dateFields.map(({ key, label, feedback, required }) => {
          const max = formData.end_date
            ? new Date(new Date(formData.end_date).getTime() + 86400000)
            : undefined;

          return (
            <MDBValidationItem
              key={key}
              className="mb-3"
              invalid
              feedback={feedback}
            >
              <DatePicker
                inputLabel={
                  required ? <RequiredLabel>{label}</RequiredLabel> : label
                }
                required={required}
                value={formData[key]}
                max={max}
                onChange={(val) => onChangeDate(key, val)}
              />
            </MDBValidationItem>
          );
        })}
      </MDBCol>
    </MDBRow>
  );
};

export default Step3_ExtraDates;
