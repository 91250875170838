import React, { useEffect,useState } from 'react';
import {    
  MDBSelect,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBListGroup,
	MDBListGroupItem,
	MDBSpinner
 } from 'mdb-react-ui-kit';
import { fetch_get } from '../../../../services/common';
import { AddRole } from '../../users/utils/AddRole';
import { RemoveRole } from '../../users/utils/RemoveRole';

export default function KansliRolesModal(params){

	const userKey = params.sentUser.user_key_id;
	const userRow = params.sentUser;
	const [loading, setLoading] = useState(false)
	const [loadingSelect, setLoadingSelect] = useState(false)
	const [userRoles, setUserRoles] = useState(null)
	const [user, setUser] = useState(null)
	const [roleAddedToast, setRoleAddedToast] = useState(false)
	const [roleErrorToast, setRoleErrorToast] = useState(false)
	const [roleRemovedToast, setRoleRemovedToast] = useState(false)
	const [selectVal, setSelectVal] = useState(null)
	const [selectList, setSelectList] = useState(null)
	const [roleRemovedErrorToast, setRoleRemovedErrorToast] = useState(false)
	const [roleVal,setRoleVal] = useState(null)
	const [roleList, setRoleList] = useState(null)
	const [sportList, setSportList] = useState([]);
	const [roleChanged, setRoleChanged] = useState(false)
	const [addRoleDisabled, setAddRoleDisabled] = useState(true)
	const [skyttekortSportgren, setSkyttekortSportgren] = useState(null)

	useEffect(() => {
		setLoading(true)
		fetch_get("app/list/sportgren-skyttekort").then((data) => {
					setTimeout(() => {
						setSkyttekortSportgren(data.data)
					}, 2000)
				})
		fetch_get("/api/app/list/lov-roles")
		.then((data) =>
			setTimeout(() => {
				setRoleList(data.data.filter(({ value }) => value === "200" || value === "300" || value === "400" /* || value === "500" */))
			}, 3000)
		);
		fetch_get("/api/app/user/all/"  + userKey)
		.then((data) =>
			setTimeout(() => {
				setUser(data.data)
				setLoading(false)
			}, 3000)
		);
	}, []);

	useEffect(() => {
		setLoading(true)
		fetch_get("/api/app/user/roller/" + userKey)
		.then((data) =>
			setTimeout(() => {
				if(data.data.length > 0){
					setUserRoles(data.data)
				} else {
					setUserRoles(null)
				}
				setLoading(false)
			}, 3000)
		);
	}, [roleChanged]);

	useEffect(() => {
		setAddRoleDisabled(true)
		if(roleVal && roleVal.value !== "200" && selectVal){
			setAddRoleDisabled(false)
		}
		if(roleVal && roleVal.value === "200"){
			setAddRoleDisabled(false)
		}
		if(userRoles && roleVal){
			if(roleVal.value === "200"){
				if(userRoles.find(({ id }) => id === roleVal.value)){
					setAddRoleDisabled(true)
				}
			} 
			if(selectVal){
				if(roleVal.value === "300"){
					if(userRoles.find(({ id, forening_id }) => id === roleVal.value && forening_id === selectVal.value)){
						setAddRoleDisabled(true)
					}
				} else if (roleVal.value === "400"){
					if(userRoles.find(({ id, distrikt_id }) => id === roleVal.value && distrikt_id === selectVal.value)){
						setAddRoleDisabled(true)
					}
				} else if (roleVal.value === "500"){
					if(userRoles.find(({ id, gren_id }) => id === roleVal.value && gren_id === selectVal.value)){
						setAddRoleDisabled(true)
					}
				}
			}
		}
	}, [userRoles,roleVal, selectVal]);

	useEffect(() => {
		if(roleVal && user){
			setLoadingSelect(true)
			if(roleVal.value >= 200 && roleVal.value < 300){
				setSelectList([{text: '-'}])
				setLoadingSelect(false)
			} else if(roleVal.value >= 300 && roleVal.value < 400){
				fetch_get("/api/app/list/lov-foreningar")
					.then((data) =>
						setTimeout(() => {
							const forening_ids = userRow.forening_id.split(","); 
							setSelectList(data.data.filter((forening) => forening_ids.includes(forening.value)))
							setLoadingSelect(false)
						}, 3000)
					);
			} else if (roleVal.value >= 400 && roleVal.value < 500){
				fetch_get("/api/app/list/lov-distrikt")
				.then((data) =>
					setTimeout(() => {
						const district = userRow.distrikt_id.split(","); 
						setSelectList(data.data.filter((distrikt) => district.includes(distrikt.value)))
						setLoadingSelect(false)
					}, 3000)
				);
			} else if (roleVal.value >= 500 && roleVal.value < 600){
				fetch_get("/api/app/list/lov-grenar")
				.then((data) =>
					setTimeout(() => {
						if(data.data[0]){
							const sportArray = (name, id, formName, secondary) => { return { text: name, value: id, name: formName, secondaryText: secondary}};
							const sportRows = [{ text: '', hidden: true }];
							data.data.map(sport => {
								const selectSport = sportArray(sport.text, Number(sport.value), "sport", sport.secondaryText);
								sportRows.push(selectSport);
							},);
							setSportList(sportRows)
						} 
						setSelectList(filterSportList(user.skyttekort))
						setLoadingSelect(false)
					}, 3000)
				);
			} else {
				setSelectList([{text: '-'}])
				setLoadingSelect(false)
			}
		}
	}, [roleVal]);

	const addRole = () => {
		
		if(roleVal.value >= 200 && roleVal.value < 300){
			AddRole(userKey,roleVal.value,0).then(obj => {
				if(obj.status == "OK"){
					setRoleChanged(!roleChanged)
					setRoleAddedToast((prev) => !prev);
				} else {
					setRoleErrorToast((prev) => !prev)
				}
			});
		} else {
			AddRole(userKey,roleVal.value,selectVal.value).then(obj => {
				if(obj.status == "OK"){
					setRoleChanged(!roleChanged)
					setRoleAddedToast((prev) => !prev);
				} else {
					setRoleErrorToast((prev) => !prev)
				}
			});
		}
		
	}

	const removeRole = (role) => {

		let obj_id = 0

		if(role.id >= 300 && role.id < 400){
			obj_id = role.forening_id
		} else if (role.id >= 400 && role.id < 500){
			obj_id = role.distrikt_id
		} else if (role.id >= 500 && role.id < 600){
			obj_id = role.gren_id
		}
		RemoveRole(userKey,role.id,obj_id).then(obj => {
			if(obj.status == "OK"){
				setRoleRemovedToast((prev) => !prev);
				setRoleChanged(!roleChanged)
			} else {
				setRoleRemovedErrorToast((prev) => !prev)
			}
		});
		
	}

	function filterSportList(skyttekortList){
		const skyttekortIdsArray = []
		skyttekortList.map(skyttekort => {
			if(skyttekort.status === "ACTIVE"){
				skyttekortIdsArray.push(skyttekort.def_skyttekort_id);
			}
		})
		const validSportgrenarArray = []
		skyttekortSportgren.map(sportgren => {
			skyttekortIdsArray.map(id => {
				if(sportgren.def_skyttekort_id == id){
					validSportgrenarArray.push(Number(sportgren.def_sportgrenar_id))
				}
			})
		})
		const filteredSportList = sportList.filter( function( element ) {
			return validSportgrenarArray.includes( element.value );
		})
		filteredSportList.unshift({ text: "", hidden: true })
		return filteredSportList
	}

	function listRoles(){
		if(user){
			return <MDBListGroup style={{ minWidth: '22rem' }} light small>
						{userRoles.map((role,i) => {
							if(role.id !== "100"){
								return <MDBListGroupItem className='d-flex justify-content-between align-items-center' key={userKey + i}>
													{role.description}{role.id === "200" ? "" : " - "} 
													{role.gren_name ? role.gren_name : null}
													{role.forening_name ? role.forening_name : null}
													{role.distrikt_name ? role.distrikt_name : null}
													{role.allow_edit === "TRUE" ?
													<MDBPopconfirm modal btnChildren='Ta bort roll' onConfirm={() => removeRole(role)}>
														<MDBPopconfirmMessage>Ta bort roll <b>{role.text}</b> från användare  <b>{userRow.first_name} {userRow.last_name}</b></MDBPopconfirmMessage>
													</MDBPopconfirm>
												 : null}
													
													
												</MDBListGroupItem>
							}
						})}
					</MDBListGroup>
		}
		
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleAddedToast}
						onClose={() => setRoleAddedToast(false)}
					>
						Roll har lagts till för {userRow.first_name} {userRow.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleErrorToast}
						onClose={() => setRoleErrorToast(false)}
					>
						Roll kunde inte läggas till.
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedToast}
						onClose={() => setRoleRemovedToast(false)}
					>
						Roll har tagits bort för {userRow.first_name} {userRow.last_name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={roleRemovedErrorToast}
						onClose={() => setRoleRemovedErrorToast(false)}
					>
						Roll kunde inte tas bort.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Hantera roller - {userRow.first_name} {userRow.last_name}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
								{!loading ? 
								<>
									<MDBRow>
										<MDBCol md={4}>
											<MDBSelect
												label='Roll'
												data={roleList ? roleList : [{ text: 'Laddar...'}]}
												name="role"			
												onChange={(val) => (setRoleVal(val),setSelectVal(null))}
												preventFirstSelection
											/>
										</MDBCol>
										{roleVal ? 
											<>
											{(roleVal.value >= 300 && roleVal.value < 400) ? 
												<MDBCol md={5}>
													<MDBSelect
														label="Förening"
														data={selectList ? loadingSelect ? [{ text: 'Laddar...'}] : selectList : [{ text: 'Laddar...'}]}
														name="association"			
														onChange={(val) => setSelectVal(val)}
														preventFirstSelection
													/>
												</MDBCol> : null}
											{(roleVal.value >= 400 && roleVal.value < 500) ? 
											<MDBCol md={5}>
												<MDBSelect
													label="Distrikt"
													data={selectList ? loadingSelect ? [{ text: 'Laddar...'}] : selectList : [{ text: 'Laddar...'}]}
													name="district"			
													onChange={(val) => setSelectVal(val)}
													preventFirstSelection
												/>
											</MDBCol> : null}
											{(roleVal.value >= 500 && roleVal.value < 600) ? 
											<MDBCol md={5}>
												<MDBSelect
													label="Grenar"
													data={selectList ? loadingSelect ? [{ text: 'Laddar...'}] : selectList : [{ text: 'Laddar...'}]}
													name="gren"			
													onChange={(val) => setSelectVal(val)}
													preventFirstSelection
												/>
											</MDBCol> : null}
											</>
										: null}
										<MDBCol className='d-flex justify-content-end'>
											<MDBPopconfirm disabled={addRoleDisabled} modal btnChildren='Lägg till roll' onConfirm={() => addRole()}>
												<MDBPopconfirmMessage>Tilldela roll <b>{roleVal ? roleVal.text : null}</b> till användare  <b>{userRow.first_name} {userRow.last_name}</b></MDBPopconfirmMessage>
											</MDBPopconfirm>
										</MDBCol>
									</MDBRow>
									{userRoles && userRoles.length > 0 ? 
									<>
										<br/>
										<hr/>
										<br/>
										<h5>Nuvarande roller</h5>
										{listRoles()}
									</> : null}
								</> : 
								<MDBRow className="d-flex justify-content-center py-5">
									<MDBSpinner color='primary'>
										<span className='visually-hidden'>Loading...</span>
									</MDBSpinner>
								</MDBRow>}
								</MDBModalBody>
            	<MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}