import React, { useEffect, useState } from "react";
import { useLoaderData, Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { fetch_get, loggedin } from "services/common";
import jwt_decode from "jwt-decode";
import StartPageCard from "components/ui/StartPageCard";
import "./HomePage.css";

export const loader = async ({ params }) => {
  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }

  const obj = {
    user: await fetch_get("app/user/all/" + decoded.kid),
    changelog: await fetch_get("data/changelog"),
    info_text: await fetch_get("app/list/info-text/7"),
    cards: await fetch_get("app/menu/homepage_cards"),
  };
  return obj;
};

export default function HomePage() {
  const loadObject = useLoaderData();
  const changelog = loadObject.changelog.data;
  const [showChangelog, setShowChangelog] = useState(false);
  const toggleChangelog = () => setShowChangelog(!showChangelog);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const cardData = loadObject.cards?.data ?? [];

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const loggedInStatus = await loggedin();
      setIsLoggedIn(loggedInStatus);
    };
    checkIsLoggedIn();
  }, []);

  const sortedCards = cardData
    .filter((card) => card.type === "card")
    .sort((a, b) => Number(a.sort_order) - Number(b.sort_order))
    .map((card) => ({
      title: card.label,
      icon: <MDBIcon fas icon={card.icon} />,
      url: card.to,
      className: card.classname,
    }));

  return (
    <MDBContainer className="home-page">
      {/* <MDBRow className="pt-4 text-center d-flex flex-column align-items-center">
        <h1>Välkommen till SkytteTa</h1>
        <p>Tävlingsadministrationsportalen för Svenska Skyttesportförbundet</p>
      </MDBRow> */}

      <MDBRow className="gy-4 my-5">
        {isLoggedIn ? (
          <></>
        ) : (
          // <MDBCol xs={12} className="d-flex">
          //   <StartPageCard
          //     title="Välkommen till SkytteTa"
          //     icon={<MDBIcon fas icon="hands-helping" />}
          //     className="flex-grow-1 welcome"
          //     url="/welcome"
          //   >
          //     <div className="d-flex flex-column flex-grow-1">
          //       <p className="mb-3">
          //         SkytteTa är den officiella tävlingsadministrationsportalen för
          //         <strong> Svenska Skyttesportförbundet</strong>. Här kan du
          //         hantera tävlingar, registrera resultat och hålla koll på
          //         kommande evenemang.
          //       </p>
          //       <p className="mb-3">
          //         Oavsett om du är tävlingsarrangör, skytt eller domare –
          //         SkytteTa hjälper dig att hålla allt organiserat på ett smidigt
          //         sätt.
          //       </p>
          //       <Link className="mt-auto" to="/getting-started">
          //         Kom igång med SkytteTa
          //       </Link>
          //       <footer className="blockquote-footer pt-3">
          //         Har du frågor? Besök vår <Link to="/help">hjälpsida</Link> för
          //         mer information.
          //       </footer>
          //     </div>
          //   </StartPageCard>
          // </MDBCol>
          <>
            <MDBCol xs={12} className="d-flex">
              <StartPageCard
                title="Logga in för första gången"
                icon={<MDBIcon fas icon="cogs" />}
                className="flex-grow-1 settings"
                url="/forgot-password"
              >
                <div className="d-flex flex-column flex-grow-1">
                  <p className="mb-3">
                    Innan man loggar in första gången behövs en återställning av
                    lösenordet. <br />
                    Detta görs genom att ange personnummer, IdrottID
                    (IdrottOnline ID) eller E-post.
                  </p>
                  <Link className="mt-auto" to="/forgot-password">
                    Återställ lösenord
                  </Link>
                  <footer className="blockquote-footer pt-3">
                    OBS! Användare måste vara upplagd i IdrottOnline, samt
                    föreningen aktiverad mot SkytteTa
                  </footer>
                </div>
              </StartPageCard>
            </MDBCol>

            <MDBCol xs={12} className="d-flex">
              <StartPageCard
                title="Information"
                icon={<MDBIcon fas icon="info-circle" />}
                className="flex-grow-1 info"
                url="/event-list"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: loadObject.info_text.data.text,
                  }}
                ></div>
              </StartPageCard>
            </MDBCol>
          </>
        )}
      </MDBRow>

      <MDBRow className="gy-4 my-5">
        {sortedCards.map((card, index) => (
          <MDBCol key={index} xs={12} md={6} className="d-flex">
            <StartPageCard
              title={card.title}
              icon={card.icon}
              url={card.url}
              className={`flex-grow-1 ${card.className}`}
            />
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
}
