import React, { useState, useEffect } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import { MDBTreeview, MDBTreeviewItem } from "mdb-react-treeview";
import { fetch_get } from "services/common";

const EventModalSportGrenar = ({
  sportgrenarModal,
  setSportgrenarModal,
  toggleSportgrenarOpen,
  addsportgren,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch_get("app/list/sportgrenar-tree-event")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sportgrenar:", error);
        setLoading(false);
      });
  }, [loading]);

  const handleClick = (e, id, name) => {
    addsportgren(id, name);
    toggleSportgrenarOpen();
  };

  const treeLoop = (row) => {
    if (row.children) {
      return (
        <MDBTreeviewItem key={row.value} subtree name={row.name}>
          {row.children.map((child) =>
            child.children ? (
              treeLoop(child)
            ) : (
              <MDBTreeviewItem
                key={child.value}
                name={
                  <div className="d-flex justify-content-between w-100">
                    <div>{child.name}</div>
                    <div>
                      <MDBBtn
                        color="secondary"
                        size="sm"
                        title="Lägg till sportgren"
                        onClick={(e) =>
                          handleClick(e, child.value, child.tree_text)
                        }
                        id={"add-sportgren-" + child.value}
                      >
                        <MDBIcon fas icon="angle-double-right" />
                      </MDBBtn>
                    </div>
                  </div>
                }
                id={`${child.name}-${child.value}`}
                value={child.value}
              />
            )
          )}
        </MDBTreeviewItem>
      );
    } else {
      return <MDBTreeviewItem key={row.value} name={row.name} />;
    }
  };

  return (
    <MDBModal
      tabIndex="-1"
      open={sportgrenarModal}
      nonInvasive={false}
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Ange sportgren</MDBModalTitle>
            <MDBBtn
              type="button"
              className="btn-close"
              color="none"
              onClick={toggleSportgrenarOpen}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBTreeview line openOnItemClick={true}>
              {data.map((row) => treeLoop(row))}
            </MDBTreeview>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              type="button"
              color="secondary"
              onClick={toggleSportgrenarOpen}
            >
              Stäng
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default EventModalSportGrenar;
