import React from "react";
import "./DisciplineClassRow.css";

const DisciplineClassRow = ({
  klass,
  sportgren_id,
  handlePriceChange,
  handleEnabledChange,
  rowIndex,
}) => {
  return (
    <div
      className={`discipline-row-outer ${rowIndex % 2 === 1 ? "alt-bg" : ""}`}
    >
      <div className="discipline-row-inner">
        <div
          className="flex-grow-1 me-1 text-truncate"
          title={klass.class_name}
        >
          <strong>{klass.class_name}</strong>
        </div>
        <div className="discipline-price-input-box">
          <input
            type="number"
            className="form-control form-control-sm price-input"
            value={klass.price}
            onChange={(e) =>
              handlePriceChange(sportgren_id, klass.class_id, e.target.value)
            }
            onBlur={(e) =>
              handlePriceChange(
                sportgren_id,
                klass.class_id,
                e.target.value.replace(/^0+(?=\d)/, "")
              )
            }
          />
        </div>

        <div className="form-check form-switch">
          <input
            type="checkbox"
            className="form-check-input"
            checked={klass.enabled}
            onChange={(e) =>
              handleEnabledChange(
                sportgren_id,
                klass.class_id,
                e.target.checked
              )
            }
            id={`enabled-${klass.class_id}`}
          />
          {/* <label
            htmlFor={`enabled-${klass.class_id}`}
            className="form-check-label mb-0"
          >
            Aktiv
          </label> */}
        </div>
      </div>
    </div>
  );
};

export default DisciplineClassRow;
