import React, { useEffect,useState } from 'react';
import {    
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
	MDBTypography,
	MDBCardText,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,   
	MDBIcon,
	MDBBtn,
	MDBBadge,
	MDBSpinner,
	MDBRadio,
	MDBCheckbox,
	MDBAlert,
	MDBValidation,
	MDBValidationItem,
	MDBInput
 } from 'mdb-react-ui-kit';
import { fetch_get, fetch_post } from "../../../../services/common";
import { GetValidThru,newDate } from '../../license/utils/GetLicenseDates';
import GetActiveLicense from '../../license/utils/GetActiveLicense';
import GetSkyttekortStatus from '../../skyttekort/utils/GetSkyttekortStatus';
import EditSkyttekortModal from '../../skyttekort/EditSkyttekortModal';
import EditLicenseModal from '../../license/EditLicenseModal';
import KansliRolesModal from './KansliRolesModal';
import { AddSkyttekort } from '../../skyttekort/utils/AddSkyttekort';
import { EditSkyttekort } from '../../skyttekort/utils/EditSkyttekort';
 
export default function KansliMembersDetailModal(params){

	const sentUser = params.sentUser
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)
	const [profilePicutre, setProfilePicture] = useState('https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png');
	const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
	const [editSkyttekortModal, setEditSkyttekortModal] = useState(false);
	const [editLicenseModal, setEditLicenseModal] = useState(false);
	const [roleModal,setRoleModal] = useState(false);
	const [skyttekortList, setSkyttekortList] = useState(null);
	const [activeSkyttekortList, setActiveSkyttekortList] = useState(null);
	const [passwordChangedToast,setPasswordChangedToast] = useState(false);
	const [changesSavedToast,setChangesSavedToast] = useState(false);
	const [loadingPassword, setLoadingPassword] = useState(false)
	const [pwFeedback, setPwFeedback] = useState("Ange ett lösenord");
	const [mainAssociation, setMainAssociation] = useState(null);
	const [passworldFormValue, setPasswordFormValue] = useState({
		newPassword: ''
	})
	const [alertMsg, setAlertMsg] = useState("Nya lösenorden matchar inte.");
	const [savingAlertMsg, setSavingAlertMsg] = useState("Ändringar sparade");
	const [saving, setSaving] = useState(false)

	const toggleEditSkyttekortModal = () => {
		setEditSkyttekortModal(!editSkyttekortModal)
	};

	const toggleEditLicenseModal = () => {
		setEditLicenseModal(!editLicenseModal)
	};

	const toggleRoleModal = () => {
		setRoleModal(!roleModal)
	};

	useEffect(() => {
		fetch_get("app/list/lov-skyttekort")
				.then((data) =>
					setTimeout(() => {
						setSkyttekortList(data.data);
						const skyttekortObj = {}
						data.data.map(skyttekort => (
							skyttekortObj[skyttekort.value] = false
						))
					}, 3000)
				);
	}, []);

	useEffect(() => {
		if(!editSkyttekortModal && !editLicenseModal && !roleModal && skyttekortList){
			setLoading(true);
			fetch_get("app/user/all/" + sentUser.user_key_id)
				.then((data) =>
					setTimeout(() => {
						setUser(data.data);
						setActiveSkyttekortList(data.data.skyttekort.map(skyttekort => {
							if(skyttekort.status === "ACTIVE"){
								return skyttekort.sportgren_id
							}
						}))
						setMainAssociation(data.data.forening_id)
						setLoading(false)
					}, 3000)
				);
		}
	}, [editSkyttekortModal, editLicenseModal, roleModal,skyttekortList]);

	useEffect(() => {
		if(user && user.profile_picture_link){
			fetch_get("app/document/get_document/" + user.profile_picture_link)
				.then((data) =>
					setTimeout(() => {
						setProfilePicture(data.data)
					}, 3000)
				);
		}
		
	}, [user]);

	const onPasswordChange = (e) => {
		setPasswordFormValue({ ...passworldFormValue, [e.target.name]: e.target.value });
		if(passworldFormValue.newPassword.length == 0){
			setPwFeedback("Ange ett lösenord")
		}
	};

	const onRadioChange = async (e) => {
		setSaving(true)
		const body = {
			"action": "edit_main_association",
			"db_forening_id": e.target.id
		};
		let errors = false;
		const resJson = await fetch_post('app/user/do_submit/' + sentUser.user_key_id, body);
		if(!resJson){
			errors = true
		} 
		setChangesSavedToast(true)
		setMainAssociation(e.target.id)
		setSavingAlertMsg(errors ? "Något gick fel" : "Ändringar sparade")
		setSaving(false)
	}

	const onCheckboxChange = async (e) => {
		const existingSkyttekort = user.skyttekort.find(({ sportgren_id }) => sportgren_id === e.target.name);
		setSaving(true)
		let errors = false;
		if(e.target.checked){
			if(existingSkyttekort){
				await EditSkyttekort("ACTIVE", sentUser.user_key_id,existingSkyttekort.id).then(obj => {
					if(obj.status != "OK"){
						errors = true
					}
				});
			} else {
				await AddSkyttekort(sentUser.user_key_id,e.target.name,sentUser.iid_nr).then(obj => {
					if(obj.status != "OK"){
						errors = true;
					} 
				});		
			}
		} else {
			if(existingSkyttekort){
				await EditSkyttekort("DELETED", sentUser.user_key_id,existingSkyttekort.id).then(obj => {
					if(obj.status != "OK"){
						errors = true
					} 
				});
			} else {
				errors = true;
			}
		}
		setChangesSavedToast(true)
		setSavingAlertMsg(errors ? "Något gick fel" : "Ändringar sparade")
		setSaving(false)
	}

	const changePassword = async (e) => {
		e.preventDefault();
		
		if (e.target.checkValidity()) {
			if(passworldFormValue.newPassword.length > 0){
				setLoadingPassword(true)
				const response = await fetch_post("auth/sysadmin-change-password", {
					key_id: sentUser.user_key_id,
					new_password: passworldFormValue.newPassword});
					if (response.status !== "OK") {
						setLoadingPassword(false)
						setAlertMsg("Det gick inte att byta lösenord")
						setPasswordChangedToast(true)
					} else {
						setLoadingPassword(false)
						setAlertMsg("Lösenorder är bytt")
						setPasswordChangedToast(true)
					}
			}
		} 
	}

	function showRoles(){
		return user.roles.map(role => {
			if(role.id != "100"){
				return true
			}
		})
	}

	function roleName(role){

		if(role.id !== "100"){
			let fullName = role.description;
			if(role.forening_name){
				fullName = fullName.concat(" - ",role.forening_name)
			}
			if(role.distrikt_name){
				fullName = fullName.concat(" - ",role.distrikt_name)
			}
			if(role.gren_name){
				fullName = fullName.concat(" - ",role.gren_name)
			}
	
			return <MDBCardText className="mb-1 text-muted">{fullName} {role.allow_edit === "TRUE" ? "(SkytteTa)" : "(Hämtar från IOL)"}</MDBCardText>
		}

	}

	function listAssociations(){
		const associationArray = []
		user.foreningar.map(forening => {
			if(forening.forening_id === mainAssociation){
				associationArray.unshift(	<div className="text-muted" key={forening.forening_id}>
																		<MDBRadio disabled={saving} onChange={onRadioChange} key={forening.forening_id} name='foreningRadio' id={forening.forening_id} defaultChecked={mainAssociation == forening.forening_id ? true : false} label={mainAssociation == forening.forening_id ? forening.name + " (Huvudförening)" : forening.name}/>
																	</div>)
			} else {
				associationArray.push(<div className="text-muted" key={forening.forening_id}>
																<MDBRadio disabled={saving} onChange={onRadioChange} key={forening.forening_id} className='text-muted' name='foreningRadio' id={forening.forening_id} defaultChecked={mainAssociation == forening.forening_id ? true : false} label={mainAssociation == forening.forening_id ? forening.name + " (Huvudförening)" : forening.name}/>
															</div>)
			}
		})
		return associationArray
	}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={passwordChangedToast}
						onClose={() => setPasswordChangedToast(false)}
					>
						{alertMsg}
					</MDBAlert>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={changesSavedToast}
						onClose={() => setChangesSavedToast(false)}
					>
						{savingAlertMsg}
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="xl">
							<MDBModalContent> 
							<MDBModalHeader>
								<MDBModalTitle>{sentUser.user_fullname}</MDBModalTitle>
								<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
							</MDBModalHeader>
							<MDBModalBody>
							{loading ?
								<MDBRow className="d-flex justify-content-center py-5">
									<MDBSpinner color='primary'>
										<span className='visually-hidden'>Loading...</span>
									</MDBSpinner>
								</MDBRow> : 
								<MDBRow className="justify-content-center align-items-center h-100">
								<MDBCol className="mb-4 mb-lg-0">
									<MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
										<MDBRow className="g-0">
											<MDBCol md="2" className="text-center"
												style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
												<MDBCardImage src={profilePicutre}
													alt="Avatar" className="my-3 rounded-3" style={{ width: '180px',height: '180px', objectFit: 'cover' }} fluid />									
												<MDBRow className='d-flex justify-content-center'>
													<MDBCol md="10">
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBTypography tag="h5">Personuppgifter</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.first_name} {user.last_name}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Födelsedatum: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.birth_date}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">IdrottsID: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">E-postadress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.email}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Förening: </MDBTypography></MDBCol>
															<MDBCol>{user.foreningar.length == 0 ? <span> Saknas</span> :
																	listAssociations()
																}
															</MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Utländsk: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Kön: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.gender ? user.gender === "M" ? "Man" : "Kvinna" : "-"}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBTypography tag="h5">Adressuppgifter</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Adress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.address1}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Postnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.zip}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Postort: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.city}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
														</MDBRow>
													</MDBRow>
													<MDBTypography className="mt-1" tag="h5">Telefonnummer</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Mobil: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.cell_phone}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Arbete: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.phone}</span></MDBCol>
														</MDBRow>
													</MDBRow>
													<MDBTypography className="mt-1" tag="h5">Närmast anhörig/ICE</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Telefonnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
										</MDBRow>
										<MDBRow className="g-0">
											<MDBCol md="3">
												<MDBCardBody className="p-4">
													<MDBRow>
														<MDBCol md={9}><MDBTypography tag="h6">Skyttekort {GetSkyttekortStatus(user.skyttekort) ? 
															<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
															: <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
														}
														
														</MDBTypography>
														</MDBCol>
														<MDBCol className='text-end' md={3}>
															<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleEditSkyttekortModal()} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
																<MDBIcon className='py-0' fas icon='cog'/>
															</MDBBtn>
														</MDBCol>
													</MDBRow>	
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBCol md="12" className="mb-3">
															{skyttekortList && activeSkyttekortList ? skyttekortList.map(skyttekort => {
																if(activeSkyttekortList.includes(skyttekort.value)){
																	return <MDBCheckbox disabled={saving} name={skyttekort.value} onChange={onCheckboxChange} id={skyttekort.value} key={skyttekort.value} className="my-1 text-muted" label={skyttekort.text} defaultChecked/>
																} else {
																	return <MDBCheckbox disabled={saving} name={skyttekort.value} onChange={onCheckboxChange} id={skyttekort.value} key={skyttekort.value} className="my-1 text-muted" label={skyttekort.text} />
																}
															}): null}
														</MDBCol>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											<MDBCol md="4">
												<MDBCardBody className="p-4">
													<MDBRow>
														<MDBCol md={9}>
															<MDBTypography tag="h6">Tävlingslicens {GetActiveLicense(user.licenser) ? 
																<MDBPopover
																	poperStyle={{width:150}}
																	dismiss
																	tag='a'
																	style={{ cursor: 'pointer'}}
																	placement='left'
																	btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
																>
																	<MDBPopoverHeader>Grenar</MDBPopoverHeader>
																	<MDBPopoverBody>
																		{GetSkyttekortStatus(user.skyttekort) ? user.skyttekort.map(kort => {
																			if(kort.status === "ACTIVE"){
																				return <MDBCardText key={kort.id} className="my-1 text-muted">{kort.gren_name}</MDBCardText>
																			}
																		}) : <MDBCardText>Saknas</MDBCardText>}
																	</MDBPopoverBody>
																	</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
															}</MDBTypography>
														</MDBCol>
														<MDBCol className='text-end' md={3}>
															<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleEditLicenseModal()} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
																<MDBIcon className='py-0' fas icon='cog'/>
															</MDBBtn>
														</MDBCol>
													</MDBRow>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={6}><MDBTypography tag="h6">Licensnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={6}><MDBTypography tag="h6">Giltig tom.: </MDBTypography></MDBCol>
																<MDBCol>
																	{GetValidThru(user.licenser) == currentDate ? "-" : GetValidThru(user.licenser)}
																</MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											{showRoles() ?
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBRow>
														<MDBCol md={9}>
															<MDBTypography tag="h6">Roller</MDBTypography>
														</MDBCol>
														<MDBCol md={3}>
															<MDBCol className='text-end' md={3}>
																<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleRoleModal()} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
																	<MDBIcon className='py-0' fas icon='cog'/>
																</MDBBtn>
															</MDBCol>
														</MDBCol>
													</MDBRow>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
													<MDBCol md="12" className="mb-3">
															{user.roles.length > 0 ? user.roles.map(role => {
																return roleName(role)
															}) : <MDBCardText>Saknas</MDBCardText>}
														</MDBCol>
													</MDBRow>
												</MDBCardBody>
											</MDBCol> : null}
										</MDBRow>
										<MDBRow className="g-0">											
											<MDBCol md={4}>
												<MDBCardBody className="p-4">
													<MDBTypography className="mt-1" tag="h5">Ändra lösenord</MDBTypography>
													<hr className="mt-0 mb-2" />
													<MDBValidation onSubmit={changePassword} className='row g-3'>
														<MDBValidationItem feedback={pwFeedback} invalid>
															<MDBInput
																name='newPassword'
																onChange={onPasswordChange}
																id='newPassword'
																required
																label='Nytt lösenord'
															/>
														</MDBValidationItem>
														<div className='col-12'>
															<MDBBtn disabled={loadingPassword} type='submit'>Spara lösenord
																{loadingPassword ? <MDBSpinner size='sm' role='status' tag='span' className='mx-2' /> : null}
															</MDBBtn>
														</div>
													</MDBValidation>
												</MDBCardBody>
											</MDBCol>
										</MDBRow>
									</MDBCard>
								</MDBCol>
							</MDBRow>}
							{(sentUser && editSkyttekortModal) ? <EditSkyttekortModal sentUser={sentUser} show={editSkyttekortModal} close={() => setEditSkyttekortModal(false)}/> : null}						
							{(sentUser !== null && editLicenseModal) ? <EditLicenseModal sentUser={sentUser} show={editLicenseModal} close={() => setEditLicenseModal(false)}/> : null}						
							{(sentUser !== null && roleModal) ? <KansliRolesModal sentUser={sentUser} show={roleModal} close={() => setRoleModal(false)}/> : null}
												
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn color='secondary' onClick={params.close}>
								Stäng
							</MDBBtn>
						</MDBModalFooter>
					</MDBModalContent>
					</MDBModalDialog>
				</MDBModal>
				
			</>
  
}