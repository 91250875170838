import React from 'react';
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody
} from 'mdb-react-ui-kit';

export default function LinkTable({ loading, link_data }) {
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <MDBTable className='p-4'>
          <MDBTableHead>
            <tr>
              <th>Link</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {link_data.map((item) => (
              <tr key={item.id}>
                <td><a href={item.to} target="_blank" rel="noopener noreferrer">{item.label}</a></td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      )}
    </>
  );
}