import React, { useState } from "react";
import { MDBIcon, MDBBtn, MDBBadge } from "mdb-react-ui-kit";
import { fetch_post } from "services/common";

const EventAccordionHeaderRight = ({
  item,
  isLoggedIn,
  openAccordion,
  toggleAccordion,
  className = "",
}) => {
  const [favorite, setFavorite] = useState(item.favorite);

  const handleFavoriteToggle = async (event) => {
    event.stopPropagation();
    const newFavorite = favorite === "1" ? false : true;
    const params = {
      action: "event_toggle_favorite",
      favorite: newFavorite,
    };

    try {
      const response = await fetch_post(
        "app/event/do_submit/" + item.key_id,
        params
      );

      if (response.status !== "ok") {
        throw new Error("Failed to toggle favorite");
      }

      setFavorite(newFavorite ? "1" : "0");
    } catch (error) {
      console.error("Error toggling favorite:", error);
      setFavorite(favorite === "1" ? "0" : "1");
    }
  };

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-end gap-2 flex-lg-row align-items-lg-center justify-content-lg-between event-cursor-pointer col-lg-3 gap-lg-0 h-100 ${className}`}
      onClick={() => toggleAccordion(item.key_id)}
    >
      <span className="event-badge col-lg-6">
        <MDBBadge
          className="order-lg-first"
          pill
          light
          color={item.status_color}
        >
          {item.status_name}
        </MDBBadge>
      </span>

      <span className="col-lg-3 order-lg-last d-flex justify-content-end">
        <MDBBtn
          size="sm"
          color="light"
          className="event-toggle order-lg-last shadow-0 border border-1 col-lg-4"
        >
          <span className="d-flex align-center">
            <MDBIcon
              icon={openAccordion === item.key_id ? "angle-up" : "angle-down"}
              className="fs-5"
            />
          </span>
        </MDBBtn>
      </span>

      {isLoggedIn && (
        <span className="col-lg-3">
          <MDBBtn
            className="event-favorite bg-transparent"
            size="sm"
            color="white"
            onClick={handleFavoriteToggle}
          >
            <MDBIcon
              fas
              icon="heart"
              className={favorite === "1" ? "text-danger" : "text-muted"}
            />
          </MDBBtn>
        </span>
      )}
    </div>
  );
};

export default React.memo(EventAccordionHeaderRight);
