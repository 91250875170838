import React from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBIcon,
} from "mdb-react-ui-kit";

const EventsPageFilterModal = ({
  toggleModal,
  setToggleModal,
  filterValue,
  setFilterValue,
  defaultFilterValue,
  lovs,
}) => {
  const customWeekdaysNarrow = ["S", "M", "T", "O", "T", "F", "L"];
  const customMonthsFull = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ];
  const customWeekdaysShort = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];
  const customMonthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ];

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setToggleModal(false);
    }
  };

  const isValidDate = (date) => {
    if (!date) return false;

    if (typeof date === "string") {
      const parsedDate = new Date(date);
      return !isNaN(parsedDate.getTime()) && date.match(/^\d{4}-\d{2}-\d{2}$/);
    }

    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleDateChange = (key, value) => {
    if (!isValidDate(value)) {
      return;
    }

    setFilterValue((prev) => {
      const updatedFilter = { ...prev, [key]: value };
      return updatedFilter;
    });
  };

  const handleFilterChange = (key, value) => {
    setFilterValue((prev) => ({ ...prev, [key]: value }));
  };

  const resetLevels = (level, value) => {
    setFilterValue((prev) => {
      let updatedFilter = { ...prev };

      if (prev[level] === value) {
        return prev;
      }

      updatedFilter[level] = value;

      if (level === "niva1") {
        updatedFilter.niva2 = "0";
        updatedFilter.niva3 = "0";
        updatedFilter.niva4 = "0";
      } else if (level === "niva2") {
        updatedFilter.niva3 = "0";
        updatedFilter.niva4 = "0";
      } else if (level === "niva3") {
        updatedFilter.niva4 = "0";
      }

      return updatedFilter;
    });
  };

  return (
    <MDBModal
      animationDirection="left"
      open={toggleModal}
      tabIndex="-1"
      backdrop={true}
      onClick={handleBackdropClick}
    >
      <MDBModalDialog className="m-0 m-sm-1" position="top-left" side size="sm">
        <MDBModalContent className="h-100" onClick={(e) => e.stopPropagation()}>
          <MDBModalHeader>
            <MDBModalTitle>Filter</MDBModalTitle>
            <MDBBtn
              color="none"
              className="btn-close"
              onClick={() => setToggleModal(false)}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>
            <MDBContainer className="w-100 p-3 d-flex flex-column gap-3">
              <MDBRow>
                <div
                  className="position-relative w-100"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    name="free_text"
                    className="form-control pe-5"
                    type="text"
                    placeholder="Text sök"
                    value={filterValue.free_text}
                    onChange={(e) =>
                      handleFilterChange("free_text", e.target.value)
                    }
                  />
                  <MDBIcon
                    fas
                    icon="search"
                    className="position-absolute end-0 pe-2 top-50 translate-middle-y me-3"
                  />
                </div>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBDatepicker
                    name="start_date"
                    format="yyyy-mm-dd"
                    inline
                    startDay={1}
                    weekdaysNarrow={customWeekdaysNarrow}
                    monthsFull={customMonthsFull}
                    weekdaysShort={customWeekdaysShort}
                    monthsShort={customMonthsShort}
                    inputLabel="Från datum"
                    max={new Date(filterValue.end_date)}
                    value={filterValue.start_date}
                    onChange={(e) => handleDateChange("start_date", e)}
                  />
                </MDBCol>

                <MDBCol>
                  <MDBDatepicker
                    name="end_date"
                    format="yyyy-mm-dd"
                    inline
                    startDay={1}
                    weekdaysNarrow={customWeekdaysNarrow}
                    monthsFull={customMonthsFull}
                    weekdaysShort={customWeekdaysShort}
                    monthsShort={customMonthsShort}
                    inputLabel="Till datum"
                    min={new Date(filterValue.start_date)}
                    value={filterValue.end_date}
                    onChange={(e) => handleDateChange("end_date", e)}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBSelect
                  name="kategori"
                  label="Kategori"
                  data={lovs.kategorier || []}
                  value={filterValue.kategori}
                  onChange={(e) => handleFilterChange("kategori", e.value)}
                />
              </MDBRow>

              <MDBRow>
                <MDBSelect
                  name="forening"
                  label="Förening"
                  data={lovs.foreningar || []}
                  value={filterValue.forening}
                  onChange={(e) => handleFilterChange("forening", e.value)}
                />
              </MDBRow>

              <MDBRow>
                <MDBSelect
                  name="distrikt"
                  label="Distrikt"
                  data={lovs.distrikt || []}
                  value={filterValue.distrikt}
                  onChange={(e) => handleFilterChange("distrikt", e.value)}
                />
              </MDBRow>

              <MDBRow>
                <MDBSelect
                  name="status"
                  label="Status"
                  data={lovs.status || []}
                  value={filterValue.status}
                  onChange={(e) => handleFilterChange("status", e.value)}
                />
              </MDBRow>

              <MDBRow>
                <MDBSelect
                  name="niva1"
                  label="Kommitté"
                  data={lovs.niva1 || []}
                  value={filterValue.niva1}
                  onChange={(e) => resetLevels("niva1", e.value)}
                />
              </MDBRow>

              {lovs.niva2.length > 1 && (
                <MDBRow>
                  <MDBSelect
                    name="niva2"
                    label="Gren"
                    data={lovs.niva2 || []}
                    value={filterValue.niva2}
                    onChange={(e) => resetLevels("niva2", e.value)}
                  />
                </MDBRow>
              )}

              {lovs.niva3.length > 1 && (
                <MDBRow>
                  <MDBSelect
                    name="niva3"
                    label="Disciplin"
                    data={lovs.niva3 || []}
                    value={filterValue.niva3}
                    onChange={(e) => resetLevels("niva3", e.value)}
                  />
                </MDBRow>
              )}

              {lovs.niva4.length > 1 && (
                <MDBRow>
                  <MDBSelect
                    name="niva4"
                    label="Subdisciplin"
                    data={lovs.niva4 || []}
                    value={filterValue.niva4}
                    onChange={(e) => resetLevels("niva4", e.value)}
                  />
                </MDBRow>
              )}

              <MDBRow className="text-center">
                <MDBCol>
                  <MDBBtn
                    color="primary"
                    onClick={() => setFilterValue(defaultFilterValue)}
                    className="w-100"
                  >
                    Nollställ Filter
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(EventsPageFilterModal);
