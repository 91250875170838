import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step8_Summary = ({ formData, lovTypes, lovKategorier, stepTitle }) => {
  const getNameFromLov = (lovList, id) => {
    const match = lovList.find((item) => String(item.value) === String(id));
    return match?.text || id || "-";
  };

  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={15} />

        <div className="summary-scroll-box">
          <ul className="list-group summary-list">
            <li className="list-group-item">
              <strong>Namn:</strong> {formData.name}
            </li>
            <li className="list-group-item">
              <strong>Tävlingstyp:</strong>{" "}
              {getNameFromLov(lovTypes, formData.event_type_id)}
            </li>
            <li className="list-group-item">
              <strong>Kategori:</strong>{" "}
              {getNameFromLov(lovKategorier, formData.kategori_id)}
            </li>
            <li className="list-group-item">
              <strong>Plats:</strong> {formData.plats}
            </li>
            <li className="list-group-item">
              <strong>Startdatum:</strong> {formData.start_date}
            </li>
            <li className="list-group-item">
              <strong>Slutdatum:</strong> {formData.end_date}
            </li>
            <li className="list-group-item">
              <strong>Publiceringsdatum:</strong> {formData.publicering_date}
            </li>
            <li className="list-group-item">
              <strong>Anmälan öppnar:</strong> {formData.anmalan_start_date}
            </li>
            <li className="list-group-item">
              <strong>Sista anmälningsdag:</strong> {formData.anmalan_end_date}
            </li>
            <li className="list-group-item">
              <strong>Kontaktperson:</strong> {formData.kontakt_first_name}
            </li>
            <li className="list-group-item">
              <strong>E-post:</strong> {formData.kontakt_email}
            </li>
            <li className="list-group-item">
              <strong>Telefon:</strong> {formData.kontakt_phone}
            </li>
            <li className="list-group-item">
              <strong>Hemsida:</strong> {formData.hemsida || "-"}
            </li>
            <li className="list-group-item">
              <strong>Arrangörer:</strong>{" "}
              {formData.arrangorer.length > 0
                ? formData.arrangorer.map((a) => a.name).join(", ")
                : "-"}
            </li>
            <li className="list-group-item">
              <strong>Sportgrenar:</strong>{" "}
              {formData.sportgrenar.length > 0
                ? formData.sportgrenar.map((s) => s.sportgren).join(", ")
                : "-"}
            </li>
            <li className="list-group-item">
              <strong>Kontonummer:</strong> {formData.betalning_kontonr || "-"}
            </li>
            <li className="list-group-item">
              <strong>Swish nummer:</strong>{" "}
              {formData.betalning_swish_nr || "-"}
            </li>
            <li className="list-group-item">
              <strong>Sista betalningsdag:</strong>{" "}
              {formData.betalning_end_date || "-"}
            </li>
            <li className="list-group-item">
              <strong>Betalningsinformation:</strong>{" "}
              {formData.betalning_information || "-"}
            </li>
          </ul>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default Step8_Summary;
