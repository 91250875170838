import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";
//dhdhd
export default function NotInPayex({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab3") {
      fetch_json_full_url("https://www.skytteta.se/api/data/not_in_payex")
        .then((response) => {
          setData(response.data);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  const tableColumns = useMemo(
    () => [
      { accessorKey: "order_id", header: "OrderId", size: 60 },
      { accessorKey: "amount", header: "Sum kr", size: 60 },
      { accessorKey: "add_date", header: "Skapad", size: 150 },
      { accessorKey: "reciever_name", header: "Receiver Name", size: 200 },
      { accessorKey: "iid_nr", header: "IID Nr", size: 60 },
      { accessorKey: "license_id", header: "License ID", size: 60 },
      { accessorKey: "lic_status", header: "Lic Status", size: 60 },
      { accessorKey: "sportgren_name", header: "Sportgren Name", size: 200 },
      { accessorKey: "payment_method", header: "Payment Method", size: 80 },
      { accessorKey: "gender", header: "Gender", size: 60 },
      { accessorKey: "status", header: "Status", size: 60 },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: 'compact',
    }, // Sätter standardantal rader per sida
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
