import { GetLatestStartDate, GetEndDate } from "../utils/GetLicenseDates"
import { fetch_post } from "../../../../services/common";
import CompareDates from "../utils/CompareDates";

export async function AddLicenseMulti(user_arr){

	const arr = []

	user_arr.map(user => {
		arr.push({	db_user_id : user.id,
								db_licens_id : (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 21) ? 4 : 7,
								db_start_date : GetLatestStartDate(user.licenser),
								db_end_date : specialEndDate(user.licenser),
								db_expired_date : specialEndDate(user.licenser),
								db_status: CompareDates(GetLatestStartDate(user.licenser),GetEndDate(user.licenser))
							}) 
	})

	function specialEndDate(licenses){
		const startDate = new Date(GetLatestStartDate(licenses))
		if(startDate.getFullYear() < 2025){
			return "2025-12-31";
		} else {
			return GetEndDate(licenses)
		}
	}
	
	const response = await fetch_post("app/user/do_submit/", {
		action: "add_license_multi",
		user_arr: arr,
	});
	
	if (response.message !== "OK") {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	} 
	return  response;
}