import { useLoaderData } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {
  Outlet,
  Link
} from "react-router-dom";
import { fetch_get,fetch_json } from "../../../services/common";
import {    
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
	MDBBtn
 } from 'mdb-react-ui-kit';
import jwt_decode from "jwt-decode";
import NotFound404 from "../NotFound404";

export async function loader({ params }) {

  const data = localStorage.getItem("token");
  var decoded = "";
  if (data) {
    decoded = jwt_decode(data);
  }

	const obj = {
		user: await fetch_get("app/user/all/" + decoded.kid),
		sportList: await fetch_get("app/list/lov-skyttekort")
		}
	return obj;
}

export default function AssociationRoot() {

  const loadObject = useLoaderData();
	const user = loadObject.user.data;
	if(!user.roles.find(({ id }) => id === "200")){
		return <NotFound404></NotFound404>
	}
	const [showMenu, setShowMenu] = useState(false);
	const [showMenuToggle, setShowMenuToggle] = useState(false);
  const toggleShow = () => setShowMenu(!showMenu);
	const url = window.location.href;
	const urlIdx = url.lastIndexOf('/');
	const urlEnding = url.substring(urlIdx + 1);
	const [active,setActive] = useState(urlEnding)

	const checkResize = () => {
		if(window.innerWidth < 992){
			setShowMenuToggle(true)
			setShowMenu(false)
		} else {
			setShowMenuToggle(false)
			setShowMenu(true)
		}
  };

	useEffect(() => {
    checkResize();
    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
		
  }, []);

  return (
		<>
			{showMenuToggle ? <MDBContainer fluid>
						<MDBBtn size="lg" color='tertiary' onClick={() => toggleShow()}>
							<MDBIcon fas icon='bars' />
						</MDBBtn>
			</MDBContainer> : null}
			
		
	<MDBCollapse open={showMenu} tag="nav" className={showMenuToggle ? 'd-lg-block bg-white sidebar' : 'd-lg-block bg-white sidebar float-start'} style={{minWidth: '220px'}} >
		<div className="position-sticky">
		<h5 className='bg-primary border-3 text-white border-white px-4 p-2 mb-1 border'>Kansli</h5>
			<MDBListGroup className="mx-1 mt-0">
				<div className='bg-light fw-bold px-4'>Medlemmar</div>
				{/* <MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "members"} >
						<Link to={"members"} className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("members")) : () => setActive("members")}>
							<MDBIcon fas icon="users" className="fa-fw me-3" />
							Medlemmar
						</Link>
					</MDBListGroupItem>
				</MDBRipple> */}
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "members"} >
						<Link to="members" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("members")) : () => setActive("members")}>
							<MDBIcon fas icon="id-card" className="fa-fw me-3" />
							Medlemmar
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				{/* <MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "roles"} >
						<Link to="roles" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("roles")) : () => setActive("roles")}>
							<MDBIcon fas icon="user-tag" className="fa-fw me-3" />
							Roller
						</Link>
					</MDBListGroupItem>
				</MDBRipple> */}
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "education"} >
						<Link to="education" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("education")) : () => setActive("education")}>
							<MDBIcon fas icon="user-graduate" className="fa-fw me-3" />
							Utbildning
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "sysadmin-login"} >
						<Link to="sysadmin-login" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("sysadmin-login")) : () => setActive("sysadmin-login")}>
							<MDBIcon fas icon="user-lock" className="fa-fw me-3" />
							Användarinloggning
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Föreningar</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "associations"} >
						<Link to="associations" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("associations")) : () => setActive("associations")}>
							<MDBIcon fas icon="house" className="fa-fw me-3" />
							Föreningar
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Anläggningar/Skjutbanor</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "facilities"} >
						<Link to="facilities" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("facilities")) : () => setActive("facilities")}>
							<MDBIcon fas icon="warehouse" className="fa-fw me-3" />
							Anläggningar 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "range"} >
						<Link to="range" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("range")) : () => setActive("range")}>
							<MDBIcon fas icon="bullseye" className="fa-fw me-3" />
							Skjutbanor 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Ekonomi</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "payment"} >
						<Link to="payment" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("payment")) : () => setActive("payment")}>
							<MDBIcon fas icon="money-check-alt" className="fa-fw me-3" />
							Betalningshistorik 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "accounting"} >
						<Link to="accounting" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("accounting")) : () => setActive("accounting")}>
							<MDBIcon fas icon="file-invoice-dollar" className="fa-fw me-3" />
							Bokföringsunderlag 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Rapport</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "statistics"} >
						<Link to="statistics" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("statistics")) : () => setActive("statistics")}>
							<MDBIcon fas icon="chart-pie" className="fa-fw me-3" />
							Statistik 
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<div className='bg-light fw-bold px-4'>Systeminställningar</div>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "license-settings"} >
						<Link to="license-settings" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("license-settings")) : () => setActive("license-settings")}>
							<MDBIcon fas icon="id-card" className="fa-fw me-3" />
							Tävlingslicens  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "organizer"} >
						<Link to="organizer" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("organizer")) : () => setActive("organizer")}>
							<MDBIcon fas icon="sitemap" className="fa-fw me-3" />
							Arrangör  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "series"} >
						<Link to="series" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("series")) : () => setActive("series")}>
							<MDBIcon fas icon="list-ol" className="fa-fw me-3" />
							Serie  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "sport"} >
						<Link to="sport" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("sport")) : () => setActive("sport")}>
							<MDBIcon fas icon="clipboard-list" className="fa-fw me-3" />
							Gren  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "event-class"} >
						<Link to="event-class" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("event-class")) : () => setActive("event-class")}>
							<MDBIcon fas icon="list" className="fa-fw me-3" />
							Tävlingsklasser
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "event-type"} >
						<Link to="event-type" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("event-type")) : () => setActive("event-type")}>
							<MDBIcon fas icon="check-double" className="fa-fw me-3" />
							Tävlingstyp  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
				<MDBRipple rippleTag='span'>
					<MDBListGroupItem action className='border-0 border-bottom rounded' active={active === "events"} >
						<Link to="events" className="sidenav-link" state={user} onClick={showMenuToggle ? () => (setShowMenu(false),setActive("events")) : () => setActive("events")}>
							<MDBIcon fas icon="calendar-alt" className="fa-fw me-3" />
							Tävlingar  
						</Link>
					</MDBListGroupItem>
				</MDBRipple>
			</MDBListGroup>
		</div>
	</MDBCollapse>	
	<Outlet />
</>
);
}