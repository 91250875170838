import React, { useState } from 'react';
import { MDBFileUpload } from 'mdb-react-file-upload';
import { fetch_post } from "../../../../services/common";
import {
	MDBCard,
	MDBCardBody,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBTable,
	MDBTableHead,
	MDBTableBody
} from 'mdb-react-ui-kit';
import JSZip from "jszip";
import { XMLParser } from "fast-xml-parser";
import * as pdfjsLib from "pdfjs-dist";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";

// Dynamisk import av worker-filen
// const pdfWorker = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
// GlobalWorkerOptions.workerSrc = pdfWorker;

// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";

export default function FileUpload() {
	const [files, setFiles] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [fileName, setFileName] = useState("");
	const [xmlJson, setXmlJson] = useState(null);
	const [fees, setFees] = useState(null);
	const [responseData, setResponseData] = useState(null);

	const extractAvgift = (type, data) => {
		const items = data.items;
		for (let i = 0; i < items.length; i++) {
			if (items[i].str === type && i + 2 < items.length) {
				return items[i + 2].str;
			}
		}
		return "-999999";
	};

	const extractPdfData = async (pdfData) => {
		console.log("pdfData", pdfData);
		const loadingTask = pdfjsLib.getDocument({ data: pdfData });
		const pdf = await loadingTask.promise;
		let extractedText = "";

		//Hämta första sidan
		const page = await pdf.getPage(1);
		const textContent = await page.getTextContent();
		console.log("textContent", i, textContent);

		console.log("avgifter", extractAvgift("Avgifter", textContent));
		console.log("Moms", extractAvgift("Moms", textContent));

		extractedText += textContent.items.map((item) => item.str).join(" ") + " ";


		// Extract fees and VAT
		const fee = extractAvgift("Avgifter", textContent);
		const vat = extractAvgift("Moms", textContent);
		console.log(fee, vat);
		setFees({
			fees: fee,
			vat: vat,
		});

		return { fee, vat };
	};

	const handleFileUpload = async (event) => {

		// Återställ responseData och fileName
		setResponseData(null);
		setFileName("");

		const file = event.target.files[0];
		if (file) {
			setFileName(file.name);
			const zip = new JSZip();
			const zipContent = await zip.loadAsync(file);
			const fileNames = Object.keys(zipContent.files);
			setFileList(fileNames);

			// Extract and parse XML file if found
			const xmlFile = fileNames.find(name => name.endsWith(".xml"));
			if (xmlFile) {
				const xmlData = await zipContent.files[xmlFile].async("text");
				const parser = new XMLParser({
					ignoreAttributes: false,
					attributeNamePrefix: "@_",
					removeNSPrefix: true,
				});
				const json = parser.parse(xmlData);
				setXmlJson(json);

				// Extract and parse PDF file if found
				const pdfFile = fileNames.find(name => name.endsWith(".pdf"));
				if (pdfFile) {
					const pdfData = await zipContent.files[pdfFile].async("uint8array");
					const { fee, vat } = await extractPdfData(pdfData);

					// Include fees in the data sent to fetch_post
					const dataToSend = {
						...json,
						fees: {
							fee,
							vat,
						},
					};


					console.log("dataToSend", dataToSend);

					const resJson = await fetch_post('app/do_submit/payex_file', dataToSend);
					console.log("resJson", resJson);
					if (resJson.data) {
						setResponseData(resJson.data); // Store response data
					}
				}
			}
		}
	};

	return (
		<>
			<div className="flex flex-col items-center gap-4 p-4">
				<input
					type="file"
					accept=".zip"
					onChange={handleFileUpload}
					className="border p-2 rounded"
				/>
				{fileName && (
					<MDBCard className="w-full max-w-lg">
						<MDBCardBody className="p-4">
							<h2 className="text-lg font-bold mb-2">{fileName}</h2>
							<ul className="bg-gray-100 p-2 rounded">
								{fileList.map((name, index) => (
									<li key={index}>{name}</li>
								))}
							</ul>
						</MDBCardBody>
					</MDBCard>
				)}
				{responseData && (
					<MDBCard className="w-full max-w-lg mt-4">
						<MDBCardBody className="p-4">
							<h2 className="text-lg font-bold mb-2">Response Data</h2>
							<MDBTable>
								<MDBTableHead>
									<tr>
										{Object.keys(responseData).map((key, index) => (
											<th key={index}>{key}</th>
										))}
									</tr>
								</MDBTableHead>
								<MDBTableBody>
									<tr>
										{Object.values(responseData).map((value, index) => (
											<td key={index}>{value}</td>
										))}
									</tr>
								</MDBTableBody>
							</MDBTable>
						</MDBCardBody>
					</MDBCard>
				)}
			</div>
		</>
	);
}
