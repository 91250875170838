import React, { useState, useRef, useEffect } from 'react';
import {
  Outlet,
  Link
} from "react-router-dom";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";
// import Sidenav from "../../modules/Sidenav_events";

export const loader = async ({ params }) => {
  //const res = await fetch('https://dev.svsf-ta.se/api/app/event/all/53a6c57f-377e-11ee-9385-00505692ffcc');
  const resJson = [];

  return resJson;
};


export default function Sidenav_events() {
  const [basicOpen, setBasicOpen] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [mode, setMode] = useState("side");
  const [backdrop, setBackdrop] = useState(false);
  const sidenavContent = useRef(null);
  const [container, setContainer] = useState();

  useEffect(() => {
    setContainer(sidenavContent.current);
    if (window.innerWidth < 992) {
      setMode("over");
      setBasicOpen(false);
      setBackdrop(true);
    } else {
      setMode("side");
      setBasicOpen(true);
      setBackdrop(false);
    }

  }, []);

  const innerWidth = useRef(null);

  const checkResize = () => {
    //if (window.innerWidth === innerWidth.current) {
    //  return;
    //}
    innerWidth.current = window.innerWidth;

    if (window.innerWidth < 992) {
      setMode("over");
      setBasicOpen(false);
      setBackdrop(true);
    } else {
      setMode("side");
      setBasicOpen(true);
      setBackdrop(false);
    }
  };



  useEffect(() => {
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <>
      {/* <header>
        <MDBSideNav
          open={basicOpen}
          backdrop={backdrop}
          absolute
          // slim={true}
          // small={true}
          getOpenState={(e) => setBasicOpen(e)}
          mode={mode}
          style={{ top: '100px' }}
          contentRef={container}

        >
          <MDBSideNavMenu>
            <div className='mt-4'>
              <div id='header-content' className='ps-3'>
                <p>Administration - Tävlingar</p>
              </div>
              <hr className='mb-0' />

            </div>

            <MDBSideNavItem>
              <Link to="/events"
                className="sidenav-link"
                onClick={() => {
                  console.log('gg');
                  if (showOpen) {
                    setShowOpen(false);
                    setBasicOpen(false);
                  }
                }}
              >

                <MDBIcon fas icon="home" className="fa-fw me-3" />
                Våra tävlingar..
              </Link>
              <Link
                to="/events/new-edit"
                className="sidenav-link"
                onClick={() => {
                  console.log('gg');
                  if (showOpen) {
                    setShowOpen(false);
                    setBasicOpen(false);
                  }
                }}

              >

                <MDBIcon fas icon="home" className="fa-fw me-3" />
                Skapa Tävling
              </Link>
              <Link
                to="/events/new-edit-evenemang/0"
                className="sidenav-link"
                onClick={() => {
                  console.log('gg');
                  if (showOpen) {
                    setShowOpen(false);
                    setBasicOpen(false);
                  }
                }}

              >

                <MDBIcon fas icon="home" className="fa-fw me-3" />
                Skapa Evenemang
              </Link>              

            </MDBSideNavItem>
          </MDBSideNavMenu>
        </MDBSideNav>


        <MDBBtn
          onClick={() => {
            setBasicOpen(!basicOpen);
            setShowOpen(true);
          }}
          color='none'
          className="float-start fixed-top navbar-toggler p-2"
        >
          <MDBIcon fas icon='bars' />
        </MDBBtn>


      </header> */}




      <div ref={sidenavContent}>
        <Outlet />
      </div>
    </>
  );
}
