import React, { useState, useRef, useMemo, useEffect } from "react";
import { MDBFilters } from "mdb-react-filters";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import { MenuItem } from "@mui/material";
import {
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBDatatable,
  MDBBadge,
  MDBBtnGroup,
} from "mdb-react-ui-kit";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  Navigate,
  useNavigate,
  //useSubmit,
} from "react-router-dom";
import Icon_Svsf from "../../svg/svg_icon_svsf";
import { fetch_get, fetch_post } from "../../../services/common";

export const loader = async ({ params }) => {
  const resJson = await fetch_get("app/list/forening-events");
  //const resJson = await res.json();

  return resJson;
};

export default function EventsHome() {
  const loadObject = useLoaderData();
  const [data, setData] = useState(loadObject.data); // Originaldata
  const [filteredData, setFilteredData] = useState(loadObject.data); // Filtrerad data
  const [activeButton, setActiveButton] = useState("ongoing"); // Aktiv knapp
  const navigate = useNavigate();

  // Filtrera data baserat på activeButton
  useEffect(() => {
    if (activeButton === "ongoing") {
      setFilteredData(data.filter((row) => row.status_id < 800)); // Filtrera rader med status_id < 200
    } else if (activeButton === "archive") {
      setFilteredData(data.filter((row) => row.status_id >= 800)); // Filtrera rader med status_id >= 200
    }
  }, [activeButton, data]);

  const onLinkClick = (k, type) => {
    console.log("onLinkClick", k, type);
    if (type === "admin_event") {
      navigate("/event/" + k);
    } else if (type === "delete_draft") {
      const form_params = {
        "action": "event_delete_draft",
      };      
      fetch_post("app/event/do_submit/"  + k, form_params)
        .then((response) => {
          if (response.status === "OK") {
            //alert("Utkastet har tagits bort.");
            setData((prevData) => prevData.filter((row) => row.key_id !== k));
          } else {
            console.log(response);
            alert("Misslyckades med att ta bort utkastet.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Ett fel inträffade vid borttagning av utkastet.");
        });
    } else {
      alert("Ej klar: " + type);
    }
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: "start_date", //normal accessorKey
        header: "Start datum",
        size: 60,
      },
      {
        accessorKey: "name",
        header: "Tävling",
        size: 120,
      },
      {
        accessorKey: "status_name",
        header: "Status",
        size: 90,
        filterVariant: 'multi-select',
        Cell: ({ cell, row }) => (
          <MDBBadge pill light color={row.original.status_color}>
            {cell.getValue()}
          </MDBBadge>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: filteredData, // Använd filtrerad data
    localization: MRT_Localization_SV,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enableFacetedValues: true,
    columnFilterDisplayMode: 'popover',
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: true,
      // density: "compact",
        },
        renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key="admin_event"
        onClick={() => onLinkClick(row.original.key_id, "admin_event")}
      >
        Ändra
      </MenuItem>,
      <MenuItem
        key="copy_event"
        onClick={() => onLinkClick(row.original.key_id, "copy_event")}
      >
        Kopiera
      </MenuItem>,
      <MenuItem
        key="status_event"
        onClick={() => onLinkClick(row.original.key_id, "status_event")}
      >
        Ändra status
      </MenuItem>,
      row.original.status_id === "0" && (
        <MenuItem
          key="delete_draft"
          onClick={(event) => {
            if (window.confirm("Är du säker på att du vill ta bort utkastet?")) {
              onLinkClick(row.original.key_id, "delete_draft");
              closeMenu();
            }
            event.stopPropagation(); // Prevent event bubbling
            event.target.closest('.MuiMenu-root')?.dispatchEvent(new Event('close', { bubbles: true })); // Close the menu
          }}
        >
          Ta bort utkast
        </MenuItem>
      ),
        ],
        renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={() => navigate("/events/new-edit/")}
          size="sm"
          className="me-2"
        >
          <MDBIcon fas icon="plus" className="me-2" />
          Skapa tävling
        </MDBBtn>
      </div>
        ),
        muiTableBodyRowProps: ({ row, cell }) => ({
      onClick: (event) => {
        console.info(event, row.id, row.original.key_id, cell);
        navigate("/event/" + row.original.key_id);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <div>
          <MDBBtnGroup aria-label="Basic example" size="sm">
            <MDBBtn
              href="#"
              active={activeButton === "ongoing"}
              onClick={() => setActiveButton("ongoing")} // Uppdatera activeButton
            >
              Pågående
            </MDBBtn>
            <MDBBtn
              href="#"
              active={activeButton === "archive"}
              onClick={() => setActiveButton("archive")} // Uppdatera activeButton
            >
              Arkiv
            </MDBBtn>
          </MDBBtnGroup>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag="a"
          size="sm"
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color="info" size="lg" />
        </MDBBtn>
      </>
    ),
  });

  return (
    <>
      <header>
        {/* <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} /> */}
      </header>

      <MDBContainer className="py-4">
        <h1>Våra tävlingar</h1>
        <MaterialReactTable table={table} />
      </MDBContainer>
    </>
  );
}
