import React from "react";
import { MDBRow, MDBCol, MDBValidationItem, MDBInput } from "mdb-react-ui-kit";
import RequiredLabel from "components/common/RequiredLabel";
import RequiredFieldsNote from "components/common/RequiredFieldsNote";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step4_Contact = ({ formData, onChange, stepTitle }) => {
  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={11} />
        <RequiredFieldsNote />

        <MDBValidationItem
          className="mb-3"
          invalid
          feedback="Ange kontaktperson."
        >
          <MDBInput
            name="kontakt_first_name"
            label={<RequiredLabel>Kontaktperson</RequiredLabel>}
            required
            value={formData.kontakt_first_name}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem
          className="mb-3"
          invalid
          feedback="Ange giltig e-post."
        >
          <MDBInput
            name="kontakt_email"
            label={<RequiredLabel>E-post</RequiredLabel>}
            type="email"
            required
            value={formData.kontakt_email}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem
          className="mb-3"
          invalid
          feedback="Ange telefonnummer."
        >
          <MDBInput
            name="kontakt_phone"
            label={<RequiredLabel>Telefonnummer</RequiredLabel>}
            required
            value={formData.kontakt_phone}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem
          className="mb-3"
          invalid
          feedback="Ange en giltig hemsideadress."
        >
          <MDBInput
            name="hemsida"
            label={
              <RequiredLabel required={false}>
                Arrangörens hemsida
              </RequiredLabel>
            }
            type="url"
            placeholder="https://"
            value={formData.hemsida}
            onChange={onChange}
          />
        </MDBValidationItem>
      </MDBCol>
    </MDBRow>
  );
};

export default Step4_Contact;
