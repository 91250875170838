import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <MDBContainer className="py-5 about-page">
      <MDBRow className="justify-content-center">
        <MDBCol md={8} lg={6}>
          <MDBCard className="shadow-sm">
            <MDBCardBody className="text-center p-4">
              <MDBIcon
                icon="info-circle"
                size="3x"
                className="about-page-icon mb-4"
              />
              <h2 className="mb-3">Om SkytteTa</h2>
              <p className="text-muted">
                SkytteTa är Svenska Skyttesportförbundets officiella portal för
                tävlingsadministration.
              </p>
              <p className="text-muted">
                Här kommer du snart att kunna läsa mer om systemet, dess syfte
                och hur det används av föreningar och skyttar runt om i landet.
              </p>
              <p className="mt-4 fst-italic">
                Den här sidan är under uppbyggnad. Mer information kommer inom
                kort!
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AboutPage;
