import React from "react";
import EventDetailsFrame from "./EventDetailsFrame";
import { useNavigate } from "react-router-dom";
import EventAccordionContentsRow from "./EventAccordionContentsRow";

const EventAccordionContents = ({ item, isLoggedIn, toggleAccordion }) => {
  const navigate = useNavigate();

  const handleClick = (event, keyId) => {
    if (event.target.closest("a, button")) {
      return;
    }

    toggleAccordion(keyId);
  };

  return (
    <div
      className="bg-darker border-top px-2 pt-4 gap-3 d-flex justify-contents-center flex-column"
      onClick={(event) => handleClick(event, item.key_id)}
    >
      <div className="mx-n2 m-sm-0">
        <div className="row d-flex align-items-stretch gx-2 gap-3 gap-md-0">
          <div className="col-md-6">
            <EventDetailsFrame
              className="w-100 h-100"
              title="Tävlingsinformation"
            >
              <div className="d-flex flex-column col-12">
                <EventAccordionContentsRow
                  label="Tävling"
                  value={item.name || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Arrangör"
                  value={item.arrangorer || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Typ av tävling"
                  value={item.event_type || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Kategori"
                  value={item.kategori || "Ej angivet"}
                />
              </div>
            </EventDetailsFrame>
          </div>

          <div className="col-md-6">
            <EventDetailsFrame className="w-100 h-100" title="Datum">
              <div className="d-flex flex-column col-12">
                <EventAccordionContentsRow
                  label="Start datum"
                  value={item.start_date || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Slut datum"
                  value={item.end_date || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Öppnar för anmälan"
                  value={item.anmalan_start_date || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Sista anmälningsdatum"
                  value={item.anmalan_end_date || "Ej angivet"}
                />
              </div>
            </EventDetailsFrame>
          </div>
        </div>

        <div className="row d-flex align-items-stretch gx-2 my-3">
          <div className="col-lg-12 d-flex">
            <EventDetailsFrame className="w-100 h-100" title="Sportgrenar">
              {item.sportgrenar && item.sportgrenar.length > 0 ? (
                <ul className="list-unstyled m-0">
                  {item.sportgrenar?.map((sport, index) => (
                    <li key={index} className="d-flex align-items-center gap-2">
                      <span className="text-primary">•</span>
                      <span className="fw-bold event-row-font">
                        {sport.sportgren}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Inga sportgrenar angivna.</p>
              )}
            </EventDetailsFrame>
          </div>
        </div>

        <div className="row d-flex align-items-stretch gx-2 gap-3 gap-md-0">
          <div className="col-md-6">
            <EventDetailsFrame className="w-100 h-100" title="Övriga uppgifter">
              <div className="d-flex flex-column col-12">
                <EventAccordionContentsRow
                  label="Plats"
                  value={item.plats || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Hemsida"
                  value={
                    item.hemsida ? (
                      <a
                        href={item.hemsida}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                      >
                        {item.hemsida}
                      </a>
                    ) : (
                      <div>Ej angivet</div>
                    )
                  }
                />
              </div>
            </EventDetailsFrame>
          </div>

          <div className="col-md-6">
            <EventDetailsFrame className="w-100 h-100" title="Kontaktuppgifter">
              <div className="d-flex flex-column col-12">
                <EventAccordionContentsRow
                  label="Kontakt"
                  value={item.kontakt_first_name || "Ej angivet"}
                />
                <EventAccordionContentsRow
                  label="Email"
                  value={
                    item.kontakt_email ? (
                      <a
                        href={`mailto:${item.kontakt_email}`}
                        className="text-decoration-none"
                      >
                        {item.kontakt_email}
                      </a>
                    ) : (
                      <p>Ej angivet</p>
                    )
                  }
                />
                <EventAccordionContentsRow
                  label="Telefon"
                  value={item.kontakt_phone || "Ej angivet"}
                />
              </div>
            </EventDetailsFrame>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-row">
        <div className="d-flex gap-2 mb-sm-3 mb-4 me-sm-1 ms-sm-auto justify-content-center">
          <button
            className={`btn btn-secondary ${
              item.status_name !== "Anmälan öppen" ? "d-none" : ""
            }`}
            onClick={() =>
              navigate(
                isLoggedIn ? `/event-anmalan/${item.key_id}` : "/login-register"
              )
            }
          >
            Anmäl Dig
          </button>

          <button
            className="btn btn-primary"
            onClick={() => navigate(`/event-overview/${item.key_id}`)}
          >
            Mera Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventAccordionContents;
