import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";

export default function LicPaymentMethod({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab7") {
      fetch_json_full_url("https://www.skytteta.se/api/data/lic_payment_method")
        .then((response) => {
          const numericData = response.data.map((item) => ({
            ...item,
            "dec-24": Number(item["dec-24"]),
            "dec-24-kr": Number(item["dec-24-kr"]),
            jan: Number(item["jan"]),
            "jan-kr": Number(item["jan-kr"]),
            feb: Number(item["feb"]),
            "feb-kr": Number(item["feb-kr"]),
            mar: Number(item["mar"]),
            "mar-kr": Number(item["mar-kr"]),
            apr: Number(item["apr"]),
            "apr-kr": Number(item["apr-kr"]),
            maj: Number(item["maj"]),
            "maj-kr": Number(item["maj-kr"]),
            jun: Number(item["jun"]),
            "jun-kr": Number(item["jun-kr"]),
            jul: Number(item["jul"]),
            "jul-kr": Number(item["jul-kr"]),
            aug: Number(item["aug"]),
            "aug-kr": Number(item["aug-kr"]),
            sep: Number(item["sep"]),
            "sep-kr": Number(item["sep-kr"]),
            okt: Number(item["okt"]),
            "okt-kr": Number(item["okt-kr"]),
            nov: Number(item["nov"]),
            "nov-kr": Number(item["nov-kr"]),
            dec: Number(item["dec"]),
            "dec-kr": Number(item["dec-kr"]),
            totalt: Number(item["totalt"]),
            totalt_kr: Number(item["totalt_kr"]),
            amount: Number(item.amount),
          }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const formattedData = data.map((item) => ({
      "Payment Method": item.payment_method,
      "Dec 24": item["dec-24"],
      "Dec 24 Kr": item["dec-24-kr"],
      Jan: item.jan,
      "Jan Kr": item["jan-kr"],
      Feb: item.feb,
      "Feb Kr": item["feb-kr"],
      Mar: item.mar,
      "Mar Kr": item["mar-kr"],
      Apr: item.apr,
      "Apr Kr": item["apr-kr"],
      Maj: item.maj,
      "Maj Kr": item["maj-kr"],
      Jun: item.jun,
      "Jun Kr": item["jun-kr"],
      Jul: item.jul,
      "Jul Kr": item["jul-kr"],
      Aug: item.aug,
      "Aug Kr": item["aug-kr"],
      Sep: item.sep,
      "Sep Kr": item["sep-kr"],
      Okt: item.okt,
      "Okt Kr": item["okt-kr"],
      Nov: item.nov,
      "Nov Kr": item["nov-kr"],
      Dec: item.dec,
      "Dec Kr": item["dec-kr"],
      Totalt: item.totalt,
      "Totalt Kr": item.totalt_kr,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel format

    // Set column widths
    const columnWidths = [
      { wch: 20 }, // Payment Method
      { wch: 10 }, // Dec 24
      { wch: 10 }, // Dec 24 Kr
      { wch: 10 }, // Jan
      { wch: 10 }, // Jan Kr
      { wch: 10 }, // Feb
      { wch: 10 }, // Feb Kr
      { wch: 10 }, // Mar
      { wch: 10 }, // Mar Kr
      { wch: 10 }, // Apr
      { wch: 10 }, // Apr Kr
      { wch: 10 }, // Maj
      { wch: 10 }, // Maj Kr
      { wch: 10 }, // Jun
      { wch: 10 }, // Jun Kr
      { wch: 10 }, // Jul
      { wch: 10 }, // Jul Kr
      { wch: 10 }, // Aug
      { wch: 10 }, // Aug Kr
      { wch: 10 }, // Sep
      { wch: 10 }, // Sep Kr
      { wch: 10 }, // Okt
      { wch: 10 }, // Okt Kr
      { wch: 10 }, // Nov
      { wch: 10 }, // Nov Kr
      { wch: 10 }, // Dec
      { wch: 10 }, // Dec Kr
      { wch: 10 }, // Totalt
      { wch: 10 }, // Totalt Kr
    ];

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Add to workbook
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Create and download file
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: "payment_method",
        header: "Payment Method",
        size: 150,
        muiTableHeadCellProps: { sx: { fontWeight: "bold" } },
      },
      {
        accessorKey: "dec-24",
        header: "Dec 24",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec-24-kr",
        header: "Dec 24 Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan",
        header: "Jan",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan-kr",
        header: "Jan Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb",
        header: "Feb",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb-kr",
        header: "Feb Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar",
        header: "Mar",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar-kr",
        header: "Mar Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr",
        header: "Apr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr-kr",
        header: "Apr Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj",
        header: "Maj",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj-kr",
        header: "Maj Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun",
        header: "Jun",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun-kr",
        header: "Jun Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul",
        header: "Jul",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul-kr",
        header: "Jul Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug",
        header: "Aug",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug-kr",
        header: "Aug Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep",
        header: "Sep",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep-kr",
        header: "Sep Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt",
        header: "Okt",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt-kr",
        header: "Okt Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov",
        header: "Nov",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov-kr",
        header: "Nov Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec",
        header: "Dec",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec-kr",
        header: "Dec Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt",
        header: "Totalt",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_kr",
        header: "Totalt Kr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    // layoutMode: "grid", //"semantic", //grid-no-grow",
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
    }, // Sätter standardantal rader per sida
    renderTopToolbar: () => {
      return (
        <button
          onClick={handleExport}
          style={{ padding: "8px 16px", cursor: "pointer" }}
        >
          Exportera till Excel
        </button>
      );
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx:
        row.original.payment_method === "*** Totalt ***"
          ? { backgroundColor: "#f0f0f0", fontWeight: "bold", color: "white" }
          : {},
    }),
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
