import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";

export default function ListLicSportgren({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab8") {
      fetch_json_full_url("https://www.skytteta.se/api/data/lic_sportgren")
        .then((response) => {
          const numericData = response.data.map((item) => ({
            ...item,
            "dec-24": Number(item["dec-24"]),
            "jan": Number(item["jan"]),
            "jan_sum": Number(item["jan_sum"]),
            "feb": Number(item["feb"]),
            "feb_sum": Number(item["feb_sum"]),
            "mar": Number(item["mar"]),
            "mar_sum": Number(item["mar_sum"]),
            "apr": item["apr"] !== null ? Number(item["apr"]) : null,
            "apr_sum": item["apr_sum"] !== null ? Number(item["apr_sum"]) : null,
            "maj": item["maj"] !== null ? Number(item["maj"]) : null,
            "maj_sum": item["maj_sum"] !== null ? Number(item["maj_sum"]) : null,
            "jun": item["jun"] !== null ? Number(item["jun"]) : null,
            "jun_sum": item["jun_sum"] !== null ? Number(item["jun_sum"]) : null,
            "jul": item["jul"] !== null ? Number(item["jul"]) : null,
            "jul_sum": item["jul_sum"] !== null ? Number(item["jul_sum"]) : null,
            "aug": item["aug"] !== null ? Number(item["aug"]) : null,
            "aug_sum": item["aug_sum"] !== null ? Number(item["aug_sum"]) : null,
            "sep": item["sep"] !== null ? Number(item["sep"]) : null,
            "sep_sum": item["sep_sum"] !== null ? Number(item["sep_sum"]) : null,
            "okt": item["okt"] !== null ? Number(item["okt"]) : null,
            "okt_sum": item["okt_sum"] !== null ? Number(item["okt_sum"]) : null,
            "nov": item["nov"] !== null ? Number(item["nov"]) : null,
            "nov_sum": item["nov_sum"] !== null ? Number(item["nov_sum"]) : null,
            "dec": item["dec"] !== null ? Number(item["dec"]) : null,
            "dec_sum": item["dec_sum"] !== null ? Number(item["dec_sum"]) : null,
            "totalt_m": Number(item["totalt_m"]),
            "procent_m": Number(item["procent_m"]),
            "totalt_k": Number(item["totalt_k"]),
            "procent_k": Number(item["procent_k"]),
            "totalt": Number(item["totalt"]),
          }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const formattedData = data.map((item) => ({
      "Sportgren Name": item.sportgren_name,
      "Lic Typ": item.lic_typ,
      "Dec 24": item["dec-24"],
      Jan: item.jan,
      "Jan Sum": item.jan_sum,
      Feb: item.feb,
      "Feb Sum": item.feb_sum,
      Mar: item.mar,
      "Mar Sum": item.mar_sum,
      Apr: item.apr,
      "Apr Sum": item.apr_sum,
      Maj: item.maj,
      "Maj Sum": item.maj_sum,
      Jun: item.jun,
      "Jun Sum": item.jun_sum,
      Jul: item.jul,
      "Jul Sum": item.jul_sum,
      Aug: item.aug,
      "Aug Sum": item.aug_sum,
      Sep: item.sep,
      "Sep Sum": item.sep_sum,
      Okt: item.okt,
      "Okt Sum": item.okt_sum,
      Nov: item.nov,
      "Nov Sum": item.nov_sum,
      Dec: item.dec,
      "Dec Sum": item.dec_sum,
      "Totalt M": item.totalt_m,
      "Procent M": item.procent_m,
      "Totalt K": item.totalt_k,
      "Procent K": item.procent_k,
      Totalt: item.totalt,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel format

    // Set column widths
    const columnWidths = [
      { wch: 20 }, // Sportgren Name
      { wch: 10 }, // Lic Typ
      { wch: 10 }, // Dec 24
      { wch: 10 }, // Jan
      { wch: 10 }, // Jan Sum
      { wch: 10 }, // Feb
      { wch: 10 }, // Feb Sum
      { wch: 10 }, // Mar
      { wch: 10 }, // Mar Sum
      { wch: 10 }, // Apr
      { wch: 10 }, // Apr Sum
      { wch: 10 }, // Maj
      { wch: 10 }, // Maj Sum
      { wch: 10 }, // Jun
      { wch: 10 }, // Jun Sum
      { wch: 10 }, // Jul
      { wch: 10 }, // Jul Sum
      { wch: 10 }, // Aug
      { wch: 10 }, // Aug Sum
      { wch: 10 }, // Sep
      { wch: 10 }, // Sep Sum
      { wch: 10 }, // Okt
      { wch: 10 }, // Okt Sum
      { wch: 10 }, // Nov
      { wch: 10 }, // Nov Sum
      { wch: 10 }, // Dec
      { wch: 10 }, // Dec Sum
      { wch: 10 }, // Totalt M
      { wch: 10 }, // Procent M
      { wch: 10 }, // Totalt K
      { wch: 10 }, // Procent K
      { wch: 10 }, // Totalt
    ];

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Add to workbook
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Create and download file
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: "sportgren_name",
        header: "Sportgren Name",
        size: 150,
        muiTableHeadCellProps: { sx: { fontWeight: "bold" } },
      },
      {
        accessorKey: "lic_typ",
        header: "Lic Typ",
        size: 150,
        muiTableHeadCellProps: { sx: { fontWeight: "bold" } },
      },
      {
        accessorKey: "dec-24",
        header: "Dec 24",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan",
        header: "Jan",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jan_sum",
        header: "Jan Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb",
        header: "Feb",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "feb_sum",
        header: "Feb Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar",
        header: "Mar",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "mar_sum",
        header: "Mar Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr",
        header: "Apr",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "apr_sum",
        header: "Apr Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj",
        header: "Maj",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "maj_sum",
        header: "Maj Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun",
        header: "Jun",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jun_sum",
        header: "Jun Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul",
        header: "Jul",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "jul_sum",
        header: "Jul Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug",
        header: "Aug",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "aug_sum",
        header: "Aug Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep",
        header: "Sep",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "sep_sum",
        header: "Sep Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt",
        header: "Okt",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "okt_sum",
        header: "Okt Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov",
        header: "Nov",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "nov_sum",
        header: "Nov Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec",
        header: "Dec",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "dec_sum",
        header: "Dec Sum",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_m",
        header: "Totalt M",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_m",
        header: "Procent M",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt_k",
        header: "Totalt K",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "procent_k",
        header: "Procent K",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
      {
        accessorKey: "totalt",
        header: "Totalt",
        size: 60,
        muiTableHeadCellProps: { align: "right" },
        muiTableBodyCellProps: { align: "right" },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    // layoutMode: "grid", //"semantic", //grid-no-grow",
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
    }, // Sätter standardantal rader per sida
    renderTopToolbar: () => {
      return (
        <button
          onClick={handleExport}
          style={{ padding: "8px 16px", cursor: "pointer" }}
        >
          Exportera till Excel
        </button>
      );
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx:
        row.original.lic_typ === "*** Totalt ***"
          ? { backgroundColor: "#f0f0f0", fontWeight: "bold", color: "white" }
          : {},
    }),
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
