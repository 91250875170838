import React from "react";
import { MDBDatepicker } from "mdb-react-ui-kit";

const isValidDate = (date) => {
  if (!date) return false;
  if (typeof date === "string") {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(date);
  }
  return date instanceof Date && !isNaN(date.getTime());
};

const DatePicker = ({ value, onChange, inputLabel, required, ...rest }) => {
  const handleChange = (val) => {
    // Empty string (cleared field) or valid date → update parent
    if (val === "" || isValidDate(val)) {
      onChange(val || ""); // Pass empty string when cleared
    }
  };

  // Don't show invalid/default dates like '0000-00-00' or negative years
  const sanitizedValue =
    typeof value === "string" &&
    (value === "0000-00-00" || value.startsWith("-") || !isValidDate(value))
      ? ""
      : value;

  return (
    <MDBDatepicker
      inline
      format="yyyy-mm-dd"
      startDay={1}
      label={inputLabel}
      value={sanitizedValue}
      onChange={handleChange}
      required={required}
      monthsFull={[
        "Januari", "Februari", "Mars", "April", "Maj", "Juni",
        "Juli", "Augusti", "September", "Oktober", "November", "December",
      ]}
      monthsShort={[
        "Jan", "Feb", "Mar", "Apr", "Maj", "Jun",
        "Jul", "Aug", "Sep", "Okt", "Nov", "Dec",
      ]}
      weekdaysShort={["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"]}
      weekdaysNarrow={["S", "M", "T", "O", "T", "F", "L"]}
      {...rest}
    />
  );
};

export default DatePicker;
