import React, { useEffect,useState } from 'react';
import {    
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
	MDBTypography,
	MDBCardText,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,   
	MDBIcon,
	MDBBtn,
	MDBBadge,
	MDBSpinner
 } from 'mdb-react-ui-kit';
import { fetch_get } from "../../../../services/common";
import { GetValidThru,newDate } from '../../license/utils/GetLicenseDates';
import GetActiveLicense from '../../license/utils/GetActiveLicense';
import GetSkyttekortStatus from '../../skyttekort/utils/GetSkyttekortStatus';
import AssociationRolesModal from './AssociationRolesModal';
 
export default function AssociationMemberDetailsModal(params){

	const sentUser = params.sentUser
	const association = params.association
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)
	const [profilePicutre, setProfilePicture] = useState('https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png');
	const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
	const [roleModal,setRoleModal] = useState(false);

	const toggleRoleModal = () => {
		setRoleModal(!roleModal)
	};

	useEffect(() => {
		if(!roleModal){
			setLoading(true);
			fetch_get("app/user/all/" + sentUser.user_key_id)
					.then((data) =>
						setTimeout(() => {
							setUser(data.data);
							setLoading(false)
						}, 3000)
					);
		}
	}, [roleModal]);

	useEffect(() => {
		if(user && user.profile_picture_link){
			fetch_get("app/document/get_document/" + user.profile_picture_link)
				.then((data) =>
					setTimeout(() => {
						setProfilePicture(data.data)
					}, 3000)
				);
		}
		
	}, [user]);

	function showRoles(){
		return user.roles.map(role => {
			if(role.id != "100"){
				return true
			}
		})
	}

	function roleName(role){

		if(role.id !== "100"){
			let fullName = role.description;
			if(role.forening_name){
				fullName = fullName.concat(" - ",role.forening_name)
			}
			if(role.distrikt_name){
				fullName = fullName.concat(" - ",role.distrikt_name)
			}
			if(role.gren_name){
				fullName = fullName.concat(" - ",role.gren_name)
			}
	
			return <MDBCardText className="mb-1 text-muted">{fullName} {role.allow_edit === "TRUE" ? "(SkytteTa)" : "(Hämtar från IOL)"}</MDBCardText>
		}

	}

	function listAssociations(){
		const associationArray = []
		user.foreningar.map(forening => {
			if(user.forening_id == forening.forening_id){
				associationArray.unshift(	<div className="text-muted" key={forening.id}>
																		<b>{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}</b>
																	</div>)
			} else {
				associationArray.push(<div className="text-muted" key={forening.id}>
																{forening.name} {user.forening_id == forening.forening_id ? "(Huvudförening)" : ""}
															</div>)
			}
		})
		return associationArray
	}

	return <>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="xl">
							<MDBModalContent> 
							<MDBModalHeader>
								<MDBModalTitle>{sentUser.user_fullname}</MDBModalTitle>
								<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
							</MDBModalHeader>
							<MDBModalBody>
							{loading ?
								<MDBRow className="d-flex justify-content-center py-5">
									<MDBSpinner color='primary'>
										<span className='visually-hidden'>Loading...</span>
									</MDBSpinner>
								</MDBRow> : 
								<MDBRow className="justify-content-center align-items-center h-100">
								<MDBCol className="mb-4 mb-lg-0">
									<MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
										<MDBRow className="g-0">
											<MDBCol md="2" className="text-center"
												style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
												<MDBCardImage src={profilePicutre}
													alt="Avatar" className="my-3 rounded-3" style={{ width: '180px',height: '180px', objectFit: 'cover' }} fluid />									
												<MDBRow className='d-flex justify-content-center'>
													<MDBCol md="10">
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBTypography tag="h5">Personuppgifter</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.first_name} {user.last_name}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Födelsedatum: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.birth_date}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">IdrottsID: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">E-postadress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.email}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Förening: </MDBTypography></MDBCol>
															<MDBCol>{user.foreningar.length == 0 ? <span> Saknas</span> :
																	listAssociations()
																}
															</MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Utländsk: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Kön: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.gender ? user.gender === "M" ? "Man" : "Kvinna" : "-"}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBTypography tag="h5">Adressuppgifter</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Adress: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.address1}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Postnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.zip}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Postort: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.city}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Land: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.country}</span></MDBCol>
														</MDBRow>
													</MDBRow>
													<MDBTypography className="mt-1" tag="h5">Telefonnummer</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Mobil: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.cell_phone}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Arbete: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.phone}</span></MDBCol>
														</MDBRow>
													</MDBRow>
													<MDBTypography className="mt-1" tag="h5">Närmast anhörig/ICE</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Telefonnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted"></span></MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
										</MDBRow>
										<MDBRow className="g-0">
											<MDBCol md="2">
												<MDBCardBody className="p-4">
													<MDBTypography tag="h6">Skyttekort {GetSkyttekortStatus(user.skyttekort) ? 
															<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
															: <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
														}</MDBTypography>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBCol md="12" className="mb-3">
															{GetSkyttekortStatus(user.skyttekort) ? user.skyttekort.map(kort => {
																if(kort.status === "ACTIVE"){
																	return <MDBCardText key={kort.id} className="my-1 text-muted">{kort.gren_name}</MDBCardText>
																}
															}) : null}
														</MDBCol>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											<MDBCol md="5">
												<MDBCardBody className="p-4">
														<MDBTypography tag="h6">Tävlingslicens {GetActiveLicense(user.licenser) ? 
															<MDBPopover
																poperStyle={{width:150}}
																dismiss
																tag='a'
																style={{ cursor: 'pointer'}}
																placement='left'
																btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
															>
																<MDBPopoverHeader>Grenar</MDBPopoverHeader>
																<MDBPopoverBody>
																	{GetSkyttekortStatus(user.skyttekort) ? user.skyttekort.map(kort => {
																		return <MDBCardText key={kort.id} className="text-muted">{kort.gren_name}</MDBCardText>
																	}) : <MDBCardText>Saknas</MDBCardText>}
																</MDBPopoverBody>
																</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
														}</MDBTypography>
														
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Licensnummer: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{user.licens_nr}</span></MDBCol>
														</MDBRow>
														<MDBRow>
															<MDBCol md={4}><MDBTypography tag="h6">Giltig tom.: </MDBTypography></MDBCol>
															<MDBCol>
																{GetValidThru(user.licenser) == currentDate ? "-" : GetValidThru(user.licenser)}
															</MDBCol>
														</MDBRow>
													</MDBRow>
												</MDBCardBody>
											</MDBCol>
											{showRoles() ?
											<MDBCol md="5">
												<MDBCardBody className="p-4">
													<MDBRow>
														<MDBCol md={9}>
															<MDBTypography tag="h6">Roller</MDBTypography>
														</MDBCol>
														<MDBCol md={3}>
															<MDBCol className='text-end' md={3}>
																<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleRoleModal()} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
																	<MDBIcon className='py-0' fas icon='cog'/>
																</MDBBtn>
															</MDBCol>
														</MDBCol>
													</MDBRow>
													<hr className="mt-0 mb-1" />
													<MDBRow className="pt-1">
													<MDBCol md="12" className="mb-3">
															{user.roles.length > 0 ? user.roles.map(role => {
																return roleName(role)
															}) : <MDBCardText>Saknas</MDBCardText>}
														</MDBCol>
													</MDBRow>
												</MDBCardBody>
											</MDBCol> : null}
										</MDBRow>										
									</MDBCard>
								</MDBCol>
							</MDBRow>}
							{(sentUser !== null && roleModal) ? <AssociationRolesModal association={association} sentUser={[sentUser]} show={roleModal} close={() => setRoleModal(false)}/> : null}												
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn color='secondary' onClick={params.close}>
								Stäng
							</MDBBtn>
						</MDBModalFooter>
					</MDBModalContent>
					</MDBModalDialog>
				</MDBModal>
				
			</>
  
}