import React, { useEffect, useRef, useState } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { fetch_get } from "services/common";
import DisciplineAccordion from "../Components/DisciplineAccordion";
import DisciplineAccordionContents from "../Components/DisciplineAccordionContents";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step6_Disciplines = ({
  formData,
  setFormData,
  setSportgrenarModal,
  disciplineClasses,
  setDisciplineClasses,
  stepTitle,
  stepValidity,
  currentStep,
}) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const prevSportgrenIds = useRef([]);

  const showValidationError =
    stepValidity?.[currentStep] === false && formData.sportgrenar.length === 0;

  useEffect(() => {
    const fetchClasses = async () => {
      const currentIds = formData.sportgrenar.map((sg) => sg.sportgren_id);

      const missingIds = currentIds.filter((id) => {
        const alreadyInState =
          Array.isArray(disciplineClasses[id]) &&
          disciplineClasses[id].length > 0;
        const hasInFormData =
          formData.sportgrenar.find((sg) => sg.sportgren_id === id)?.klasser
            ?.length > 0;
        return !alreadyInState && !hasInFormData;
      });

      if (missingIds.length === 0) return;

      const promises = missingIds.map((id) =>
        fetch_get(`app/list/def_klasser/${id}`).then(({ data }) => ({
          sportgren_id: id,
          classes: data.map((klass) => ({
            class_id: klass.id,
            class_name: klass.name,
            price: klass.startavgift ?? 0,
            enabled: klass.enabled ?? true,
          })),
        }))
      );

      const results = await Promise.all(promises);

      setDisciplineClasses((prev) => {
        const updated = { ...prev };
        results.forEach(({ sportgren_id, classes }) => {
          updated[sportgren_id] = classes;
        });
        return updated;
      });
    };

    if (formData.sportgrenar.length > 0) {
      fetchClasses();
    } else {
      setDisciplineClasses({});
    }

    const currentIds = formData.sportgrenar.map((sg) => sg.sportgren_id);

    const newIds = currentIds.filter(
      (id) => !prevSportgrenIds.current.includes(id)
    );
    if (newIds.length > 0) {
      setOpenAccordion(newIds[newIds.length - 1]);
    }

    prevSportgrenIds.current = currentIds;
  }, [formData.sportgrenar]);

  const handlePriceChange = (sportgren_id, class_id, value) => {
    console.log("klass id", class_id);
    setDisciplineClasses((prev) => {
      const updated = prev[sportgren_id].map((klass) => {
        return klass.class_id === class_id ? { ...klass, price: value } : klass;
      });

      return { ...prev, [sportgren_id]: updated };
    });
  };

  const handleEnabledChange = (sportgren_id, class_id, checked) => {
    setDisciplineClasses((prev) => {
      const updated = prev[sportgren_id].map((klass) =>
        klass.class_id === class_id ? { ...klass, enabled: checked } : klass
      );
      return { ...prev, [sportgren_id]: updated };
    });
  };

  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol className="discipline-accordion">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={13} />
        <MDBBtn
          type="button"
          color="secondary"
          onClick={() => setSportgrenarModal(true)}
          size="sm"
          className="mb-3"
        >
          <MDBIcon fas icon="plus" className="me-2" /> Lägg till Sportgren
        </MDBBtn>

        {formData.sportgrenar.length === 0 ? (
          <p className="text-muted fst-italic">Ingen sportgren tillagd ännu.</p>
        ) : (
          <>
            {formData.sportgrenar.map((sg, i) => (
              <DisciplineAccordion
                key={sg.sportgren_id}
                sg={sg}
                isOpen={openAccordion === sg.sportgren_id}
                onToggle={() =>
                  setOpenAccordion((prev) =>
                    prev === sg.sportgren_id ? null : sg.sportgren_id
                  )
                }
                onRemove={() => {
                  const updated = [...formData.sportgrenar];
                  updated.splice(i, 1);
                  setFormData({ ...formData, sportgrenar: updated });
                }}
              >
                <DisciplineAccordionContents
                  key={sg.sportgren_id}
                  sg={sg}
                  index={i}
                  formData={formData}
                  setFormData={setFormData}
                  disciplineClasses={disciplineClasses}
                  setDisciplineClasses={setDisciplineClasses}
                  isOpen={openAccordion === sg.sportgren_id}
                  onToggle={() =>
                    setOpenAccordion((prev) =>
                      prev === sg.sportgren_id ? "" : sg.sportgren_id
                    )
                  }
                  handlePriceChange={handlePriceChange}
                  handleEnabledChange={handleEnabledChange}
                />
              </DisciplineAccordion>
            ))}
          </>
        )}
        {showValidationError && (
          <div className="text-danger mt-n2 mb-3">
            Minst en sportgren måste anges.
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default Step6_Disciplines;
