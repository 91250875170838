import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import { fetch_get } from "services/common";
import "./EventWizardInfoModal.css";

const EventWizardInfoModal = ({ open, onClose, stepTitle, infoTextId }) => {
  const [infoText, setInfoText] = useState("Laddar...");

  useEffect(() => {
    if (open && infoTextId) {
      fetch_get(`app/list/info-text/${infoTextId}`)
        .then((data) => {
          setInfoText(data?.data?.text || "Information saknas.");
        })
        .catch((err) => {
          console.error("Info fetch error:", err);
          setInfoText("Fel vid hämtning av information.");
        });
    }
  }, [open, infoTextId]);

  return (
    <MDBModal
      open={open}
      onClose={onClose}
      tabIndex="-1"
      staticBackdrop={false}
    >
      <MDBModalDialog centered size="md">
        <MDBModalContent className="info-modal-content">
          <MDBModalHeader className="info-modal-header">
            <MDBModalTitle className="d-flex align-items-center gap-3">
              <MDBIcon fas icon="info-circle fs-5" className="text-info" />
              <span>{stepTitle}</span>
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              type="button"
              color="none"
              onClick={onClose}
            />
          </MDBModalHeader>
          <MDBModalBody dangerouslySetInnerHTML={{ __html: infoText }} />
          <MDBModalFooter>
            <MDBBtn type="button" color="secondary" onClick={onClose}>
              Stäng
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default EventWizardInfoModal;
