import React, { useState, useEffect, useMemo } from "react";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import * as XLSX from "xlsx"; // Importera XLSX
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { fetch_json_full_url, fetch_json } from "../../../../services/common";

export default function Ta_Payex_Detail({ activeTab }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (activeTab === "tab6") {
      fetch_json_full_url("https://www.skytteta.se/api/data/ta_payex_detail")
        .then((response) => {
          const numericData = response.data.map((item) => ({
            ...item,
            amount: Number(item.amount),
          }));
          setData(numericData);
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [activeTab, dataFetched]);

  // Funktion för att exportera till Excel
  const handleExport = () => {
    const formattedData = data.map((item) => ({
      "Accounting Date": item.AccountingDate,
      "Order ID": item.order_id,
      "Amount": item.amount,
      "Description": item.description,
      "Add Date": item.add_date,
      "Receiver User ID": item.reciever_user_id,
      "Receiver Name": item.reciever_name,
      "IID Nr": item.iid_nr,
      "License ID": item.license_id,
      "License Status": item.lic_status,
      "Sportgren Name": item.sportgren_name,
      "Payment Method": item.payment_method,
      "Gender": item.gender,
      "Status": item.status,
      "Added Manually": item.added_manually,
      "Age": item.age,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel format

    // Set column widths
    const columnWidths = [
      { wch: 20 }, // Accounting Date
      { wch: 10 }, // Order ID
      { wch: 10 }, // Amount
      { wch: 30 }, // Description
      { wch: 20 }, // Add Date
      { wch: 20 }, // Receiver User ID
      { wch: 25 }, // Receiver Name
      { wch: 15 }, // IID Nr
      { wch: 15 }, // License ID
      { wch: 15 }, // License Status
      { wch: 20 }, // Sportgren Name
      { wch: 20 }, // Payment Method
      { wch: 10 }, // Gender
      { wch: 15 }, // Status
      { wch: 15 }, // Added Manually
      { wch: 10 }, // Age
    ];

    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Add to workbook
    XLSX.writeFile(workbook, "ExporteradData.xlsx"); // Create and download file
  };

  const tableColumns = useMemo(
    () => [
      { accessorKey: "AccountingDate", header: "Accounting Date", size: 150 },
      { accessorKey: "order_id", header: "Order ID", size: 100 },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 100,
        muiTableBodyCellProps: { align: "right" },
        muiTableHeadCellProps: { align: "right" },
      },
      { accessorKey: "description", header: "Description", size: 250 },
      { accessorKey: "add_date", header: "Add Date", size: 200 },
      { accessorKey: "reciever_user_id", header: "Receiver User ID", size: 150 },
      { accessorKey: "reciever_name", header: "Receiver Name", size: 200 },
      { accessorKey: "iid_nr", header: "IID Nr", size: 150 },
      { accessorKey: "license_id", header: "License ID", size: 150 },
      { accessorKey: "lic_status", header: "License Status", size: 150 },
      { accessorKey: "sportgren_name", header: "Sportgren Name", size: 150 },
      { accessorKey: "payment_method", header: "Payment Method", size: 150 },
      { accessorKey: "gender", header: "Gender", size: 100 },
      { accessorKey: "status", header: "Status", size: 150 },
      { accessorKey: "added_manually", header: "Added Manually", size: 150 },
      { accessorKey: "age", header: "Age", size: 100 },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    tableColumns,
    localization: MRT_Localization_SV,
    enableSorting: true,
    enablePagination: true,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    // layoutMode: "grid", //"semantic", //grid-no-grow",
    initialState: {
      pagination: { pageSize: 100 },
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
    }, // Sätter standardantal rader per sida
    renderTopToolbar: () => {
      return (
        <button
          onClick={handleExport}
          style={{ padding: "8px 16px", cursor: "pointer" }}
        >
          Exportera till Excel
        </button>
      );
    },
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <MaterialReactTable table={table} />
        </>
      )}
    </>
  );
}
