import { useNavigate,useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from 'react';
import { fetch_get } from "../../../services/common";
import { 
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBAlert,
  MDBValidation,
  MDBSelect,
  MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBCardFooter,
	MDBSpinner,
	MDBCardHeader,
	MDBPopconfirm,
	MDBPopconfirmMessage
  } from "mdb-react-ui-kit";
import GetActiveLicense from "./utils/GetActiveLicense";
import { BorderCol } from "../../modules/BorderCol";
import { GetLatestStartDate, GetEndDate } from './utils/GetLicenseDates';
import CompareDates from './utils/CompareDates';

export default function LicenseStepper() {

  const location = useLocation();
  const user_keys = location.state.selectedRows;
	const association_id = location.state.association_id;
	const [loading, setLoading] = useState(true);
	const [licenses, setLicenses] = useState(null);
	const [sportList, setSportList] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const navigate = useNavigate();
	const [skyttekortSportgren, setSkyttekortSportgren] = useState(null);
	const storedForm = JSON.parse(localStorage.getItem("license-form"));

	useEffect(() => {
		setLoading(true)
		fetch_get("app/list/licenser").then((data) => {
			setTimeout(() => {
				setLicenses(data.data)
			}, setLoading(false), 2000)
		})
		fetch_get("app/list/sportgren-skyttekort").then((data) => {
			setTimeout(() => {
				setSkyttekortSportgren(data.data)
			}, 2000)
		})
		fetch_get("app/list/lov-grenar").then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					const sportArray = (name, id, formName, secondary) => { return { text: name, value: id, name: formName, secondaryText: secondary}};
  				const sportRows = [{ text: '', hidden: true }];
					data.data.map(sport => {
						const selectSport = sportArray(sport.text, Number(sport.value), "sport", sport.secondaryText);
						sportRows.push(selectSport);
					},);
					setSportList(sportRows)
				} 
			}, 2000)
		})
	}, []);

	useEffect(() => {
		if(licenses && sportList.length > 0){
			setLoading(true)
			let searchString = "('"
			if(storedForm){
				storedForm.map((user,i) => {
					if(i === 0){
						searchString = searchString + user.user_key + "'";
					} else {
						searchString = searchString + ",'" + user.user_key + "'";
					}
				});
			} else {
				user_keys.map((user,i) => {
					if(i === 0){
						searchString = searchString + user.key_id + "'";
					} else {
						searchString = searchString + ",'" + user.key_id + "'";
					}
				});
			}
			fetch_get("data/users_lic/?_filter=forening_id=" + association_id + " AND key_id IN" + searchString + ")").then((data) => {
				setTimeout(() => {
					setFormValues(data.data.map(user => {
						const sportID = storedForm ? storedForm.find(obj => obj.user_key === user.key_id).sport : null
						return  {license: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 21) ? licenses.find(obj => obj.id == 4) : licenses.find(obj => obj.id == 7 ),
											sport: sportList ? sportList.find(obj => obj.value == sportID) : null,
											startDate: GetLatestStartDate(user.licenser),
											endDate: specialEndDate(user.licenser),
											price: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 21) ? licenses.find(obj => obj.id == 4 ).amount : licenses.find(obj => obj.id == 7 ).amount,
											age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 21) ? "Senior" : "Junior",
											userID: user.id,
											iid: user.licens_nr,
											name: user.first_name + " " + user.last_name,
											user_license_id: -1,
											user_key: user.key_id,
											licenseStatus: CompareDates(GetLatestStartDate(user.licenser), GetEndDate(user.licenser)),
											skyttekort: user.skyttekort}
													}))
				}, setLoading(false), 2000)
			})
		}
	}, [licenses, sportList]);

	function specialEndDate(licenses){
		const startDate = new Date(GetLatestStartDate(licenses))
		if(startDate.getFullYear() < 2025){
			return "2025-12-31";
		} else {
			return GetEndDate(licenses)
		}
	}

	const handleSubmit = (e) => {
    e.preventDefault();
    if(e.target.checkValidity()){
      navigate("/license-summary",{state: {formValues, association_id}});
    }
  };

	useEffect(() => {
		if(formValues && formValues.length > 0){
			const formArr = [];
			formValues.map(form => {
				formArr.push({"user_key": form.user_key,"sport": form.sport ? form.sport.value : null})
			})
			localStorage.setItem("license-form", JSON.stringify(formArr))
		}
	}, [formValues]);

  const onChange = (e,user) => {
		setFormValues(formValues.map(form => {
			if(form.userID === user.userID){
				return { ...form, 
					[e.name]: {value:e.value,text:e.text}};
			} else {
				return form;
			}
		}));
  };

	function filterSportList(skyttekortList){
		const skyttekortIdsArray = []
		skyttekortList.map(skyttekort => {
			if(skyttekort.status === "ACTIVE"){
				skyttekortIdsArray.push(skyttekort.def_skyttekort_id);
			}
		})
		const validSportgrenarArray = []
		skyttekortSportgren.map(sportgren => {
			skyttekortIdsArray.map(id => {
				if(sportgren.def_skyttekort_id == id){
					validSportgrenarArray.push(Number(sportgren.def_sportgrenar_id))
				}
			})
		})
		const filteredSportList = sportList.filter( function( element ) {
			return validSportgrenarArray.includes( element.value );
		})
		filteredSportList.unshift({ text: "", hidden: true })
		return filteredSportList
	}

	function removeUser(user){
		setFormValues(formValues.filter(formUser => formUser.userID !== user.userID))/* 
		const formArr = localStorage.getItem("license-form");
		localStorage.setItem("license-form", formArr.filter(formUser => formUser.user_key !== user.user_key));
		console.log(localStorage.getItem("license-form")) */
	}

	function totPrice(users){
		let price = 0;
		users.map(user => {
			price = price + Number(user.price)
		})
		return price
	}

  return (
    <>
			{loading || !formValues || !skyttekortSportgren || !licenses || sportList.length == 0 ?     
				<MDBRow className="d-flex justify-content-center py-5">
					<MDBSpinner color='primary'>
						<span className='visually-hidden'>Loading...</span>
					</MDBSpinner>
				</MDBRow> :
      <MDBContainer className="py-4"> 
        <MDBRow>
          <MDBCol md='12'>
					<BorderCol headline="Köp tävlingslicens" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
            <MDBValidation onSubmit={handleSubmit} className='row g-3'>
                {formValues.map((user) => {
                  return  <MDBCol xl={6} className='mb-4' key={user.userID}>
														<MDBCard border="light" shadow='0' className='mb-3'>
															<MDBCardHeader background='primary' className="d-flex justify-content-between">
																<b className="text-light">{user.name + " (" + user.iid + ") - " + (user.age == null ? "-" : user.age)}</b>
																{formValues.length > 1 ?
																	<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Ta bort" type="button" modal color='none' className='m-1' style={{ color: '#fff', background: 'none', border: 'none' }} btnChildren={
																		<MDBIcon fas icon="times" size='lg' />
																	} onConfirm={() => removeUser(user)}>
																		<MDBPopconfirmMessage>Ta bort medlem från licensköp?</MDBPopconfirmMessage>
																	</MDBPopconfirm> : null}
															</MDBCardHeader>
															<MDBCardBody className="pt-1 pe-2">
																<MDBRow className="d-flex justify-content-between mt-3">
																	<MDBCol md={10}>
																		<b>{user.license == null ? "-" : user.license.name}</b>
																			<MDBSelect
																			 	className="mt-3"
																				preventFirstSelection
																				data={filterSportList(user.skyttekort)}
																				label='Välj idrottskommitté'
																				onChange={(e) => onChange(e,user)}
																				value={user.sport ? user.sport.value : null}
																				validation
																				validFeedback=''
																				invalidFeedback='Ange en idrottskommitté'
																			/>
																	</MDBCol>
																</MDBRow>
															</MDBCardBody>
															<MDBCardFooter className="d-flex justify-content-between mt-3" background='light'>
																<div>
																	<strong>{user.startDate + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}</strong>
																	<strong>{user.endDate}</strong>
																</div>
																<div>
																	<strong>Pris: </strong>{user.price}kr
																</div>
															</MDBCardFooter>
														</MDBCard>
													</MDBCol>
                  
                })}
							<hr className="hr" />
							<MDBRow className="d-flex justify-content-end">
								<MDBCol md={3}>
									<p className="text-black text-center">
										<span className="text-black me-3">Summa</span>
										<span style={{ fontSize: "25px" }}>{totPrice(formValues)}kr</span>
									</p>
								</MDBCol>
							</MDBRow>
              <MDBRow>
								<MDBCol>
									<MDBBtn
										onClick={() => (localStorage.removeItem('license-form'),navigate("/association/" + association_id + "/license"))}
										color='tertiary'
										type="button"
										>
									Avbryt
								</MDBBtn>
								</MDBCol>
                <MDBCol className="d-flex justify-content-end">
                  <MDBBtn type="submit">Slutför</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBValidation>
						</BorderCol>
          </MDBCol>
        </MDBRow>
				
      </MDBContainer>}
    </>
  );
}