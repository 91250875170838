import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import "./HelpPage.css";

const HelpPage = () => {
  return (
    <MDBContainer className="py-5 help-page">
      <MDBRow className="justify-content-center">
        <MDBCol md={8} lg={6}>
          <MDBCard className="shadow-sm">
            <MDBCardBody className="text-center p-4">
              <MDBIcon
                icon="life-ring"
                size="3x"
                className="help-page-icon mb-4"
              />
              <h2 className="mb-3">Hjälp & Support</h2>
              <p className="text-muted">
                Här kommer du snart att hitta vanliga frågor, guider och
                möjligheter att få hjälp direkt från vårt supportteam.
              </p>
              <p className="mt-4 fst-italic">
                Den här sidan är under uppbyggnad. Tack för ditt tålamod!
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default HelpPage;

// #28a745;
