import React from "react";

const EventDetailsFrame = ({ title, children, className = "" }) => {
  return (
    <div className={`d-flex flex-column flex-lg-row card border border-primary px-3 pt-4 pb-3 position-relative ${className}`}>
      <span className="fw-normal badge badge-primary position-absolute" style={{ fontSize: "0.85rem", height: "20px", top: "-10px" }}>
        {title}
      </span>
      <div className="d-flex flex-column gap-2 col-12">
        {children}
      </div>
    </div>
  );
};

export default EventDetailsFrame;
