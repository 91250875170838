import React from "react";
import EventAccordionHeaderRow from "./EventAccordionHeaderRow";
import "./EventsPage.css";

const EventAccordionHeaderLeft = ({ item, toggleAccordion }) => {
  return (
    <div
      className="d-flex flex-column flex-lg-row bd-highlight align-items-stretch w-100 px-lg-3 px-2 event-cursor-pointer"
      onClick={() => toggleAccordion(item.key_id)}
    >
      <div className="d-flex align-items-center col-lg-5 justify-content-between">
        <span>
          <EventAccordionHeaderRow
            label="Datum"
            value={item.start_date ? item.start_date : "-"}
          />
          <EventAccordionHeaderRow
            label="Tävling"
            value={item.name ? item.name : "-"}
          />
        </span>
      </div>

      <div className="d-flex flex-column justify-content-center flex-grow-1">
        <EventAccordionHeaderRow
          label="Arrangör"
          value={item.arrangorer || "-"}
        />
        <EventAccordionHeaderRow
          label="Anmälan"
          value={
            item.anmalan_start_date && item.anmalan_end_date
              ? `${item.anmalan_start_date} - ${item.anmalan_end_date}`
              : "-"
          }
        />
      </div>
    </div>
  );
};

export default EventAccordionHeaderLeft;
