import React from "react";
import packageJson from "../../../package.json";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import Icon_Svsf from "../svg/svg_icon_svsf";
import "./Footer.css";
import { Link } from "react-router-dom";

const FooterSection = ({ title, lines }) => (
  <MDBCol md="4" className="d-flex flex-column mb-3 mb-md-0">
    <h6 className="footer-title">{title}</h6>
    {lines.map((line, i) => (
      <p key={i} className="footer-text">
        {line}
      </p>
    ))}
  </MDBCol>
);

const Footer = ({ id }) => {
  return (
    <MDBFooter
      id={id}
      className="footer-modern py-4 text-light text-center text-lg-start"
    >
      <section>
        <MDBContainer className="text-center text-md-start">
          <MDBRow className="mt-3">
            {/* Logo & version */}
            <MDBCol
              xs="12"
              md="2"
              className="d-flex flex-column footer-logo-col gap-md-2"
            >
              <Link
                to="/"
                className="mb-0 mb-md-1"
                aria-label="Gå till startsidan"
              >
                <Icon_Svsf className="footer-logo" />
              </Link>
              <p
                className="footer-version fw-light"
                data-mdb-toggle="tooltip"
                title={`Build Date: ${packageJson.build_date}`}
              >
                Ver: {packageJson.version}
              </p>
            </MDBCol>

            {/* Content sections */}
            <MDBCol xs="12" md="10">
              <MDBRow>
                <FooterSection
                  title="Besöksadress"
                  lines={[
                    "Skansbrogatan",
                    "118 60 Stockholm",
                    "Org-nr: 802003-1707",
                  ]}
                />
                <FooterSection
                  title="Postadress"
                  lines={[
                    "Svenska Skyttesportförbundet",
                    "Box 11016",
                    "100 61 Stockholm",
                  ]}
                />
                <FooterSection
                  title="Kontakt"
                  lines={[
                    <>
                      Tel: 08 699 63 70
                      <br />
                      E-post:{" "}
                      <a
                        href="mailto:skytteta@skyttesport.se"
                        className="footer-link"
                      >
                        skytteta@skyttesport.se
                      </a>
                    </>,
                    <>
                      <a
                        href="https://www.skyttesport.se"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                      >
                        www.skyttesport.se
                      </a>{" "}
                      <MDBIcon
                        icon="external-link-alt"
                        size="xs"
                        className="ms-1"
                      />
                    </>,
                    <>
                      <a
                        href="https://login.idrottonline.se/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                      >
                        IdrottOnline
                      </a>{" "}
                      <MDBIcon
                        icon="external-link-alt"
                        size="xs"
                        className="ms-1"
                      />
                    </>,
                  ]}
                />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
};

export default Footer;
