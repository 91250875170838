import React from "react";

const RequiredFieldsNote = ({ show = true }) => {
  if (!show) return null;

  return (
    <p className="text-muted small mb-4">
      <span className="text-danger">*</span> Obligatoriska fält
    </p>
  );
};

export default RequiredFieldsNote;
