import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import DisciplineClassRow from "./DisciplineClassRow";
import "./DisciplineAccordion.css";

const DisciplineAccordionContents = ({
  sg,
  index,
  formData,
  disciplineClasses,
  setDisciplineClasses,
}) => {
  const [defaultStartavgift, setDefaultStartavgift] = useState("");

  const updateDisciplinePrices = (newPrice) => {
    const parsedPrice = parseFloat(newPrice);
    if (isNaN(parsedPrice)) return;

    const updated = disciplineClasses[sg.sportgren_id].map((klass) => ({
      ...klass,
      price: parsedPrice,
    }));

    setDisciplineClasses((prev) => ({
      ...prev,
      [sg.sportgren_id]: updated,
    }));
    setDefaultStartavgift(String(""));
  };

  const handlePriceChange = (sportgren_id, class_id, value) => {
    const updated = disciplineClasses[sportgren_id].map((klass) => {
      return klass.class_id === class_id
        ? { ...klass, price: parseFloat(value) || 0 }
        : klass;
    });

    setDisciplineClasses((prev) => ({ ...prev, [sportgren_id]: updated }));
  };

  const handleEnabledChange = (sportgren_id, class_id, isChecked) => {
    const updated = disciplineClasses[sportgren_id].map((klass) => {
      return klass.class_id === class_id
        ? { ...klass, enabled: isChecked }
        : klass;
    });

    setDisciplineClasses((prev) => ({ ...prev, [sportgren_id]: updated }));
  };

  useEffect(() => {
    const sportgrenId = sg.sportgren_id;
    const alreadyInitialized =
      Array.isArray(disciplineClasses[sportgrenId]) &&
      disciplineClasses[sportgrenId].some((k) => k.price !== undefined);

    if (!alreadyInitialized && formData.sportgrenar?.[index]?.klasser?.length) {
      const rawKlasser = formData.sportgrenar[index].klasser;

      const initial = rawKlasser.map((klass) => ({
        ...klass,
        class_id: klass.klass_id ?? klass.event_klasser_id ?? klass.id,
        class_name: klass.klass_name ?? klass.name ?? klass.class_name ?? "",
        price: parseFloat(klass.startavgift) || 0,
        enabled:
          klass.enabled === true ||
          klass.enabled === "1" ||
          klass.status === "ENABLED",
      }));

      setDisciplineClasses((prev) => ({
        ...prev,
        [sportgrenId]: initial,
      }));
    }
  }, [sg.sportgren_id, index, formData.sportgrenar, setDisciplineClasses]);

  return (
    <>
      <div className="discipline-standard-row mb-3">
        <div className="standard-left">
          <label
            htmlFor={`default-price-${sg.sportgren_id}`}
            className="standard-label"
          >
            Standard startavgift:
          </label>
          <input
            id={`default-price-${sg.sportgren_id}`}
            type="number"
            className="form-control form-control-sm standard-price-input"
            value={defaultStartavgift}
            onChange={(e) => setDefaultStartavgift(e.target.value)}
          />
        </div>

        <div className="standard-right">
          <MDBBtn
            type="button"
            color="primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateDisciplinePrices(defaultStartavgift);
            }}
          >
            Använd
          </MDBBtn>
        </div>
      </div>

      <div className="discipline-row-inner discipline-header fw-bold text-muted mb-1 px-2">
        <div className="discipline-header-name text-truncate">Klass</div>
        <div className="discipline-header-price text-center">Startavgift</div>
        <div className="discipline-header-toggle text-center">Aktiv</div>
      </div>

      {(disciplineClasses[sg.sportgren_id] || sg.classes || []).map(
        (klass, i) => (
          <DisciplineClassRow
            key={klass.class_id ?? `${sg.sportgren_id}-${i}`}
            klass={{
              ...klass,
              price:
                klass.price !== undefined
                  ? klass.price
                  : klass.startavgift ?? 0,
              enabled: klass.enabled,
            }}
            sportgren_id={sg.sportgren_id}
            handlePriceChange={handlePriceChange}
            handleEnabledChange={handleEnabledChange}
            rowIndex={i}
          />
        )
      )}
    </>
  );
};

export default DisciplineAccordionContents;
