import React, { useEffect,useState } from 'react';
import {    
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBSpinner
 } from 'mdb-react-ui-kit';
import { fetch_post } from '../../../../services/common';

export default function KansliMemberChangePassword(params){

	const userRow = params.sentUser;
	const [passwordChangedToast,setPasswordChangedToast] = useState(false);
	const [pwFeedback, setPwFeedback] = useState("Ange ett lösenord");
	const [passworldFormValue, setPasswordFormValue] = useState({
		newPassword: ''
	})
	const [alertMsg, setAlertMsg] = useState("Nya lösenorden matchar inte.");
	const [loading, setLoading] = useState(false)

	const onChange = (e) => {
    setPasswordFormValue({ ...passworldFormValue, [e.target.name]: e.target.value });
		if(passworldFormValue.newPassword.length == 0){
			setPwFeedback("Ange ett lösenord")
		}
  };

	const changePassword = async (e) => {
			e.preventDefault();
			
			if (e.target.checkValidity()) {
				if(passworldFormValue.newPassword.length > 0){
					setLoading(true)
					const response = await fetch_post("auth/sysadmin-change-password", {
						key_id: userRow.user_key_id,
						new_password: passworldFormValue.newPassword});
						if (response.status !== "OK") {
							setLoading(false)
							setAlertMsg("Det gick inte att byta lösenord")
							setPasswordChangedToast(true)
						} else {
							setLoading(false)
							setAlertMsg("Lösenorder är bytt")
							setPasswordChangedToast(true)
						}
				}
			} 
		}

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={passwordChangedToast}
						onClose={() => setPasswordChangedToast(false)}
					>
						{alertMsg}
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBModalContent>
								<MDBModalHeader>
									<MDBModalTitle>Ändra lösenord - {userRow.first_name} {userRow.last_name}</MDBModalTitle>
									<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
								<MDBModalBody>
								<MDBValidation onSubmit={changePassword} className='row g-3'>
									<MDBValidationItem feedback={pwFeedback} invalid>
										<MDBInput
											name='newPassword'
											onChange={onChange}
											id='newPassword'
											required
											label='Nytt lösenord'
										/>
									</MDBValidationItem>
								<div className='col-12'>
									<MDBBtn disabled={loading} type='submit'>Spara lösenord
										{loading ? <MDBSpinner size='sm' role='status' tag='span' className='mx-2' /> : null}
									</MDBBtn>
								</div>
							</MDBValidation>
								</MDBModalBody>
            	<MDBModalFooter>
              <MDBBtn color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}