import React from "react";
import "./EventsPage.css";

const EventAccordionContentsRow = ({ label, value }) => {
  return (
    <span className="d-flex text-nowrap event-row-font event-contents-row">
      <span className="event-contents-row-label">{label}:</span>
      <span className="fw-bold text-truncate overflow-hidden">{value}</span>
    </span>
  );
};

export default EventAccordionContentsRow;
