import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBValidation,
  MDBIcon,
} from "mdb-react-ui-kit";
import { fetch_get, fetch_post } from "services/common";
import EventModalSportGrenar from "../EventModalSportGrenar";
import Step1_AboutEvent from "./Steps/Step1_AboutEvent";
import Step2_Dates from "./Steps/Step2_Dates";
import Step3_ExtraDates from "./Steps/Step3_ExtraDates";
import Step4_Contact from "./Steps/Step4_Contact";
import Step5_Organizers from "./Steps/Step5_Organizers";
import Step6_Disciplines from "./Steps/Step6_Disciplines";
import Step7_Payment from "./Steps/Step7_Payment";
import Step8_Summary from "./Steps/Step8_Summary";
import WizardNavigationButtons from "./Components/NavigationButtons";
import WizardStepIndicator from "./Components/StepIndicator";
import { useNavigate } from "react-router-dom";
import "./EventWizard.css";

const initialFormData = {
  name: "",
  event_type_id: "",
  kategori_id: "",
  start_date: "",
  end_date: "",
  plats: "",
  kontakt_first_name: "",
  kontakt_email: "",
  kontakt_phone: "",
  arrangorer: [],
  sportgrenar: [],
  publicering_date: "",
  anmalan_start_date: "",
  anmalan_end_date: "",
  hemsida: "",
};

const wizardSteps = [
  {
    id: 1,
    title: "Om tävlingen",
    validate: (data) =>
      !!data.name && !!data.plats && !!data.event_type_id && !!data.kategori_id,
  },
  {
    id: 2,
    title: "Tävlingens datum",
    validate: (data) => !!data.start_date && !!data.end_date,
  },
  {
    id: 3,
    title: "Övriga datum",
    validate: (data) => !!data.anmalan_start_date,
  },
  {
    id: 4,
    title: "Kontaktinfo",
    validate: (data) =>
      !!data.kontakt_first_name && !!data.kontakt_email && !!data.kontakt_phone,
  },
  {
    id: 5,
    title: "Arrangörer",
    validate: (data) => data.arrangorer?.length > 0,
  },
  {
    id: 6,
    title: "Sportgrenar",
    validate: (data) => data.sportgrenar?.length > 0,
  },
  {
    id: 7,
    title: "Betalning",
    validate: () => true,
  },
  {
    id: 8,
    title: "Sammanfattning",
    validate: () => true, // Always valid
  },
];

const totalSteps = wizardSteps.length;

const EventWizard = () => {
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [sportgrenarModal, setSportgrenarModal] = useState(false);
  const [lovTypes, setLovTypes] = useState([]);
  const [lovKategorier, setLovKategorier] = useState([]);
  const [maxReachedStep, setMaxReachedStep] = useState(1);
  const [stepValidity, setStepValidity] = useState({});
  const isEditMode = Boolean(id);
  const [isValidId, setIsValidId] = useState(true);
  const formRef = useRef(null);
  const [disciplineClasses, setDisciplineClasses] = useState({});
  const navigate = useNavigate();

  const nextStep = () => {
    if (!validateAndStoreStep(formRef.current)) return;
    if (step < totalSteps) {
      const next = step + 1;
      setStep(next);
      setMaxReachedStep((prev) => Math.max(prev, next));
    }
  };

  const prevStep = () => {
    validateAndStoreStep(formRef.current);
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const goToStep = (targetStep) => {
    if (targetStep === step) return;
    if (!validateAndStoreStep(formRef.current)) return;
    if (isEditMode || targetStep <= maxReachedStep) {
      setStep(targetStep);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeDate = (key, value) => {
    setFormData((prev) => {
      const updated = { ...prev, [key]: value };

      if (key === "start_date" && !prev.end_date) {
        updated.end_date = value;
      }

      return updated;
    });
  };

  const onSubmitEvent = async (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      await submitEvent(formData);
    } else {
      console.log("form not valid");
    }
  };

  const submitEvent = async (data) => {
    const enrichedSportgrenar = data.sportgrenar.map((sg) => ({
      ...sg,
      klasser:
        disciplineClasses[sg.sportgren_id]?.map((klass) => ({
          klass_id: klass.class_id,
          startavgift: klass.price ?? 0,
          enabled: klass.enabled ?? true,
        })) || [],
    }));

    const payload = {
      action: "event_add_edit",
      key_id: data.key_id || null,
      db_name: data.name,
      db_parent_id: data.parent_id || null,
      db_event_type_id: data.event_type_id,
      db_kategori_id: data.kategori_id,
      db_kontakt_first_name: data.kontakt_first_name,
      db_kontakt_email: data.kontakt_email,
      db_kontakt_phone: data.kontakt_phone,
      db_start_date: data.start_date,
      db_end_date: data.end_date,
      db_publicering_date: data.publicering_date,
      db_anmalan_start_date: data.anmalan_start_date,
      db_anmalan_end_date: data.anmalan_end_date,
      db_efteranmalan: data.efteranmalan === "1" || data.efteranmalan === true,
      db_efteranmalan_end_date: data.efteranmalan_end_date,
      db_plats: data.plats,
      db_hemsida: data.hemsida,
      db_betalning_end_date: data.betalning_end_date,
      db_betalning_information: data.betalning_information,
      db_betalning_kontonr: data.betalning_kontonr,
      db_betalning_swish_nr: data.betalning_swish_nr,
      arrangorer: data.arrangorer || [],
      sportgrenar: enrichedSportgrenar,
    };

    console.log("payload", payload);

    try {
      const resJson = await fetch_post(
        `app/event/do_submit/${payload.key_id || ""}`,
        payload
      );

      if (resJson?.key_id) {
        // Set key_id if newly created
        setFormData((prev) => ({
          ...prev,
          key_id: resJson.key_id,
        }));

        navigate(`/event/${resJson.key_id}`);
      } else {
        console.error("No key_id returned in response", resJson);
        throw new Error("Failed to submit event");
      }
    } catch (error) {
      console.error("Submit error:", error);
      alert("Ett fel uppstod vid sparning av tävlingen.");
    }
  };

  const validateAndStoreStep = (form, currentStep = step) => {
    const currentWizardStep = wizardSteps.find((s) => s.id === currentStep);
    const passesCustomValidation = currentWizardStep.validate(formData);
    const passesHTMLValidation = form?.checkValidity?.() ?? true;

    if (!passesHTMLValidation) {
      form.reportValidity();
    }

    setStepValidity((prev) => ({
      ...prev,
      [currentStep]: passesCustomValidation && passesHTMLValidation,
    }));

    return passesCustomValidation && passesHTMLValidation;
  };

  const validateAllSteps = (formData) => {
    const tempStepValidity = {};

    wizardSteps.forEach((step) => {
      tempStepValidity[step.id] = step.validate(formData);
    });

    setStepValidity(tempStepValidity);
    setMaxReachedStep(wizardSteps.length);
  };

  const sanitizeEvent = (event) => {
    const cleaned = { ...event };
    for (const key in cleaned) {
      if (cleaned[key] === null) {
        cleaned[key] = "";
      }
    }
    return cleaned;
  };

  useEffect(() => {
    fetch_get("data/event-lovs")
      .then(({ data }) => {
        const types = data[0]["lov-event-types"];
        const kategorier = data[0]["lov-kategorier"];
        setLovTypes(types);
        setLovKategorier(kategorier);

        return fetch_get("app/event/form_data/" + (id || ""));
      })
      .then(({ data: event }) => {
        if (!event || (id && !event.key_id)) {
          setIsValidId(false);
          return;
        }

        const sanitized = sanitizeEvent(event); // ✅ Sanitize here
        const event_type_id = String(sanitized.event_type_id ?? "");
        const kategori_id = String(sanitized.kategori_id ?? "");

        const loadedForm = {
          ...initialFormData,
          ...sanitized,
          event_type_id,
          kategori_id,
          arrangorer: sanitized.arrangorer ?? [],
          sportgrenar: sanitized.sportgrenar ?? [],
        };

        setFormData(loadedForm);

        if (id) {
          setTimeout(() => validateAllSteps(loadedForm), 0);
        }
      })
      .catch((err) => {
        console.error("Failed to load event or defaults:", err);
        setIsValidId(false);
      });
  }, [id]);

  if (id && !isValidId) {
    return (
      <MDBContainer className="py-5 text-center">
        <MDBIcon
          icon="exclamation-triangle"
          size="3x"
          className="text-danger mb-3"
        />
        <h4>Ogiltigt tävlings-ID</h4>
        <p>Vi kunde inte hitta någon tävling med angivet ID.</p>
      </MDBContainer>
    );
  }

  // useEffect(() => {
  //   console.log("formData changed", formData);
  // }, [formData]);

  return (
    <MDBContainer className="py-4 wizard-page">
      <MDBCard className="w-100 w-sm-75">
        <MDBCardBody className="wizard-body-fixed">
          <div className="wizard-header text-center mb-4">
            <MDBCardTitle className="mb-4 d-flex align-items-center justify-content-center">
              <MDBIcon
                fas
                icon="trophy"
                size="lg"
                className="me-3 text-primary"
              />
              <span className="fw-semibold fs-5">
                {isEditMode
                  ? `Redigera: ${formData.name || "Tävling"}`
                  : "Skapa en ny tävling"}
              </span>
            </MDBCardTitle>

            <WizardStepIndicator
              currentStep={step}
              maxReachedStep={maxReachedStep}
              totalSteps={totalSteps}
              onNavigate={goToStep}
              isEditMode={isEditMode}
              stepValidity={stepValidity}
              steps={wizardSteps}
            />
          </div>
          <div className="wizard-body-bottom d-flex flex-column flex-grow-1">
            <MDBValidation
              tag="form"
              ref={formRef}
              onSubmit={onSubmitEvent}
              className="wizard-content-height d-flex flex-column flex-grow-1"
            >
              <div className="wizard-body-content flex-grow-1">
                {step === 1 && (
                  <Step1_AboutEvent
                    formData={formData}
                    onChange={onChange}
                    lovTypes={lovTypes}
                    lovKategorier={lovKategorier}
                    stepTitle={wizardSteps[0].title}
                  />
                )}
                {step === 2 && (
                  <Step2_Dates
                    formData={formData}
                    onChangeDate={onChangeDate}
                    stepTitle={wizardSteps[1].title}
                  />
                )}
                {step === 3 && (
                  <Step3_ExtraDates
                    formData={formData}
                    onChangeDate={onChangeDate}
                    stepTitle={wizardSteps[2].title}
                  />
                )}
                {step === 4 && (
                  <Step4_Contact
                    formData={formData}
                    onChange={onChange}
                    stepTitle={wizardSteps[3].title}
                  />
                )}
                {step === 5 && (
                  <Step5_Organizers
                    formData={formData}
                    setFormData={setFormData}
                    stepTitle={wizardSteps[4].title}
                    stepValidity={stepValidity}
                    currentStep={step}
                  />
                )}
                {step === 6 && (
                  <Step6_Disciplines
                    formData={formData}
                    setFormData={setFormData}
                    disciplineClasses={disciplineClasses}
                    setDisciplineClasses={setDisciplineClasses}
                    sportgrenarModal={sportgrenarModal}
                    setSportgrenarModal={setSportgrenarModal}
                    stepTitle={wizardSteps[5].title}
                    stepValidity={stepValidity}
                    currentStep={step}
                  />
                )}
                {step === 7 && (
                  <Step7_Payment
                    formData={formData}
                    onChange={onChange}
                    onChangeDate={onChangeDate}
                    stepTitle={wizardSteps[6].title}
                  />
                )}
                {step === 8 && (
                  <Step8_Summary
                    formData={formData}
                    lovTypes={lovTypes}
                    lovKategorier={lovKategorier}
                    stepTitle={wizardSteps[7].title}
                  />
                )}
              </div>

              <WizardNavigationButtons
                step={step}
                totalSteps={totalSteps}
                onPrev={prevStep}
                onNext={nextStep}
                isEditMode={isEditMode}
                isFormValid={Object.values(stepValidity).every(Boolean)}
              />
            </MDBValidation>
          </div>
        </MDBCardBody>
      </MDBCard>

      {sportgrenarModal && (
        <EventModalSportGrenar
          sportgrenarModal={sportgrenarModal}
          setSportgrenarModal={setSportgrenarModal}
          toggleSportgrenarOpen={() => setSportgrenarModal(!sportgrenarModal)}
          addsportgren={(id, name) => {
            const updated = [
              ...formData.sportgrenar,
              { sportgren_id: id, sportgren: name },
            ];
            setFormData({ ...formData, sportgrenar: updated });
          }}
        />
      )}
    </MDBContainer>
  );
};

export default EventWizard;
