import React from "react";
import PropTypes from "prop-types";
import "./StartPageCard.css";

const StartPageCard = ({
  title,
  icon,
  className = "",
  children,
  url,
  clickable,
}) => {
  const isClickable = clickable ?? !children;
  const CardContent = (
    <div className="start-page-card-content">
      <div className="start-page-card-header">
        <h5 className="start-page-card-title">{title}</h5>
        {icon && <span className="start-page-card-icon">{icon}</span>}
      </div>
      {children && <hr className="start-page-card-divider" />}
      <div className="start-page-card-body">{children}</div>
    </div>
  );

  return isClickable && url ? (
    <a
      href={url}
      className={`start-page-card clickable w-100 start-page-content ${className}`}
    >
      {CardContent}
    </a>
  ) : (
    <div className={`start-page-card ${className}`}>{CardContent}</div>
  );
};

StartPageCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  url: PropTypes.string,
};

export default StartPageCard;
