import React, { useState } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import EventWizardInfoModal from "./EventWizardInfoModal";
import "../EventWizard.css";

const EventWizardContentTitle = ({ stepTitle, infoTextId = "1" }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="event-wizard-content-title d-flex justify-content-between align-items-center mb-4">
        <h5 className="mb-0">{stepTitle}</h5>
        <button
          type="button"
          className="btn btn-link p-0 m-0 text-info text-decoration-none"
          onClick={() => setModalOpen(true)}
          title="Visa mer information"
          aria-label="Visa info"
        >
          <MDBIcon fas icon="info-circle" className="me-1 fs-5" />
        </button>
      </div>

      <EventWizardInfoModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        infoTextId={infoTextId}
        stepTitle={stepTitle}
      />
    </>
  );
};

export default EventWizardContentTitle;
