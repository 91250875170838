import React, { useState, useEffect, useRef } from "react";
import { LoadingIndicator, loggedin } from "services/common";
import EventAccordionContents from "./EventAccordionContents";
import EventAccordionHeaderLeft from "./EventAccordionHeaderLeft";
import { useNavigationType } from "react-router-dom";
import EventAccordionHeaderRight from "./EventAccordionHeaderRight";
import { MDBIcon } from "mdb-react-ui-kit";
import "./EventsPage.css";

const EventAccordion = ({ data, isLoading }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);
  const navigationType = useNavigationType();
  const accordionRefs = useRef({}); // Stores refs for each item

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const loggedInStatus = await loggedin();
      setIsLoggedIn(loggedInStatus);
    };
    checkIsLoggedIn();
  }, []);

  useEffect(() => {
    const storedAccordion = sessionStorage.getItem("openAccordion");
    if (storedAccordion && navigationType === "POP") {
      setOpenAccordion(JSON.parse(storedAccordion));
    }
  }, []);

  useEffect(() => {
    if (openAccordion !== null) {
      sessionStorage.setItem("openAccordion", JSON.stringify(openAccordion));
    } else {
      sessionStorage.removeItem("openAccordion");
    }
  }, [openAccordion]);

  useEffect(() => {
    if (openAccordion !== null && accordionRefs.current[openAccordion]) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          const element = accordionRefs.current[openAccordion];
          if (!element) return;
          const elementTop =
            element.getBoundingClientRect().top + window.scrollY;

          window.scrollTo({
            top: elementTop - 20,
            behavior: "smooth",
          });
        });
      }, 50);
    }
  }, [openAccordion, data]);

  const toggleAccordion = (key_id) => {
    setOpenAccordion((prev) => (prev === key_id ? null : key_id));
  };

  if (isLoading) {
    return <LoadingIndicator message="Laddar tävlingar..." />;
  }

  if (!data || data.length === 0) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <MDBIcon
          fas
          icon="exclamation-triangle"
          className="text-warning mb-2"
          size="3x"
        />
        <p className="text-center">Inga tävlingar tillgängliga!</p>
      </div>
    );
  }

  return (
    <div className="event-accordion">
      {data.map((item, index) => (
        <div
          key={item.key_id}
          className={`event-accordion-item ${
            index % 2 === 0 ? "bg-light" : "bg-white"
          }`}
          ref={(el) => (accordionRefs.current[item.key_id] = el)}
        >
          <div className="event-accordion-header d-flex justify-content-between align-items-center px-md-4 gap-md-4 py-2 fw-semi ps-1">
            <EventAccordionHeaderLeft
              item={item}
              toggleAccordion={toggleAccordion}
            />
            <EventAccordionHeaderRight
              isLoggedIn={isLoggedIn}
              item={item}
              openAccordion={openAccordion}
              toggleAccordion={toggleAccordion}
            />
          </div>

          <div
            className={`event-accordion-content ${
              openAccordion === item.key_id ? "open" : "d-none"
            }`}
          >
            <EventAccordionContents
              item={item}
              isLoggedIn={isLoggedIn}
              toggleAccordion={toggleAccordion}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventAccordion;
