import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const NavigationButtons = ({
  step,
  totalSteps,
  onPrev,
  onNext,
  isEditMode = false,
  isFormValid = false,
}) => {
  const isLastStep = step === totalSteps;
  const navigate = useNavigate();

  const handleCancel = () => {
    const confirmCancel = window.confirm("Vill du verkligen avbryta?");
    if (confirmCancel) {
      navigate(-1);
    }
  };

  return (
    <MDBRow>
      <MDBCol className="wizard-buttons-wrapper d-flex flex-column-reverse flex-md-row justify-content-between align-items-center mt-5">
        <div className="wizard-button-group">
          <div className="button-set">
            <MDBBtn
              type="button"
              color="danger"
              className="wizard-btn"
              onClick={handleCancel}
            >
              Avbryt
            </MDBBtn>

            {isEditMode && isFormValid && (
              <MDBBtn type="submit" color="success" className="wizard-btn">
                Spara
              </MDBBtn>
            )}

            {isLastStep && !isEditMode && (
              <MDBBtn type="submit" color="success" className="wizard-btn">
                Spara
              </MDBBtn>
            )}
          </div>
        </div>

        {/* Optional mobile divider */}
        <div className="wizard-divider d-md-none" />

        <div className="wizard-button-group">
          <div className="button-set">
            {step > 1 && (
              <MDBBtn
                type="button"
                color="secondary"
                className="wizard-btn"
                onClick={onPrev}
              >
                Tillbaka
              </MDBBtn>
            )}

            {!isLastStep && (
              <MDBBtn
                type="button"
                color="primary"
                className="wizard-btn"
                onClick={onNext}
              >
                Nästa
              </MDBBtn>
            )}
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default NavigationButtons;
