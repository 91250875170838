import React, { useMemo, useState, useEffect } from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToolbarAlertBanner,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBContainer,
  MDBIcon,
  MDBBadge,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { MenuItem, ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetch_get, fetch_post } from "services/common";

export default function KansliEvents() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(100); // Aktiv knapp
  const [tableData, setTableData] = useState([]);

  // State för tabellens filter, sortering och paginering
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [eventStatuses, setEventStatuses] = useState([]); // State för eventstatusar
  const [statuses, setStatuses] = useState([]); // State för eventstatusar
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statues_groups = [
    {
      id: -1,
      name: "Alla",
      color: "primary",
      where: "1=1",
    },
    {
      id: 1000,
      name: "Ansökt",
      color: "warning",
      where: "status_id =100",
    },
    {
      id: 2000,
      name: "Aktiva",
      color: "info",
      where: "status_id > 100 AND status_id < 800",
    },
    {
      id: 3000,
      name: "Avslutade",
      color: "success",
      where: "status_id = 800",
    },
    {
      id: 4000,
      name: "Borttagen",
      color: "danger",
      where: "status_id >= 900",
    },
  ];

  // Hämta eventstatusar vid komponentens montering
  useEffect(() => {
    const fetchEventStatuses = async () => {
      try {
        const response = await fetch_get("app/list/event-statues");
        if (response.status === "ok") {
          setStatuses(response.data); // Sätt eventstatusar i state
          console.log("response.data", response.data);
          const formattedStatuses = response.data.map((status) => ({
            label: status.name,
            value: status.name,
          }));
          console.log("formattedStatuses", formattedStatuses);
          setEventStatuses(formattedStatuses); // Sätt eventstatusar i state
        } else {
          console.error("Failed to fetch event statuses:", response);
        }
      } catch (error) {
        console.error("Error fetching event statuses:", error);
      }
    };

    fetchEventStatuses();
  }, []);

  // Hämta data med React Query
  const {
    data: { data = [], meta } = {},
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "events",
      columnFilters,
      globalFilter,
      sorting,
      pagination.pageIndex,
      pagination.pageSize,
      activeButton,
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "/api/data/kansli_events/",
        window.location.protocol + "//" + window.location.hostname
      );

      // Lägg till URL-parametrar för paginering, sortering och filter
      fetchURL.searchParams.set(
        "_skip",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      fetchURL.searchParams.set("_top", `${pagination.pageSize}`);

      if (sorting.length > 0) {
        fetchURL.searchParams.set(
          "_orderby",
          `${sorting[0].id} ${sorting[0].desc ? "desc" : "asc"}`
        );
      }

      function mapFilter(filter) {
        console.log("filter", filter);
        if (filter.id === "status_name") {
          const values = Array.isArray(filter.value)
            ? filter.value
            : [filter.value];
          return `${filter.id} IN (${values
            .map((value) => `'${value}'`)
            .join(", ")})`;
        }
        return `${filter.id} LIKE '%${filter.value}%'`;
      }

      function filterFunction() {
        let filterString = "";
        console.log("start filter", columnFilters.length, columnFilters);
        if (columnFilters.length > 0) {
          // console.log("filter.id", filter.id, filter.value);
          //   if (filter.id === "status_name") {
          //   const values = Array.isArray(filter.value) ? filter.value : [filter.value];
          //   filterString = filterString.concat(` AND ${filter.id} IN (${values.map(value => `'${value}'`).join(", ")})`);
          //   } else {
          //   filterString = filterString.concat(` AND ${filter.id} LIKE '%${filter.value}%'`);
          //   }
          filterString = columnFilters
            .map((filter) => mapFilter(filter))
            // .map((filter) => `${filter.id} LIKE '%${filter.value}%'`)
            .join(" AND ");
          // fetchURL.searchParams.set("_filter", filterString);
          console.log("filterString", filterString);
        } else {
          filterString = "1=1"; // Ingen filtrering
        }

        const activeStatus = statues_groups.find(
          (status) => status.id === activeButton
        );
        if (activeStatus) {
          filterString = filterString.concat(" AND " + activeStatus.where);
        }

        console.log("filterString", filterString);

        return filterString;
      }

      if (globalFilter) {
        fetchURL.searchParams.set("_globalfilter", globalFilter);
      }

      // if(globalFilter || columnFilters.length > 0 ){
      fetchURL.searchParams.set("_filter", filterFunction());
      // }

      const response = await fetch_get(
        fetchURL.href.split(window.location.hostname).pop()
      );
      console.log("onSuccess", response.data);
      setTableData(response.data);
      return response;
    },
    onSuccess: (response) => {
      console.log("onSuccess", response.data);
      setTableData(response.data); // Uppdatera tabellens data
    },
    placeholderData: keepPreviousData, // Behåll tidigare data medan ny data laddas
  });

  const onLinkClick = (k, type, status = null) => {
    if (status) {
      console.log("onLinkClick", k, type, status);
      const form_params = {
        action: "event_change_status",
        db_status_id: status,
      };
      fetch_post("app/event/do_submit/" + k, form_params)
        .then((response) => {
          if (response.status === "OK") {
            // Uppdatera tabellens data
            setTableData(() =>
              tableData.map((item) => {
                if (item.key_id === k) {
                  const statusDetails = statuses.find((s) => s.id == status);
                  return {
                    ...item,
                    status_id: status,
                    status_color: statusDetails?.color || "danger",
                    status_name: statusDetails?.name || "*** Okänd ***",
                  };
                }
                return item;
              })
            );
          } else {
            console.log(response);
            alert("Misslyckades med att uppdatera status.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Ett fel inträffade vid status uppdateringen.");
        });
    } else {
      if (type === "admin_event") {
        navigate("/event/" + k);
      } else if (type === "delete_draft") {
      } else {
        alert("Ej klar: " + type);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "start_date",
        header: "Start datum",
        size: 60,
      },
      {
        accessorKey: "name",
        header: "Tävling",
        size: 120,
      },
      {
        accessorKey: "arrangorer",
        header: "Arrangorer",
        size: 120,
      },
      {
        accessorKey: "status_name",
        header: "Status",
        size: 90,
        filterVariant: "multi-select",
        filterSelectOptions: eventStatuses,
        Cell: ({ cell, row }) => (
          <MDBBadge pill light color={row.original.status_color}>
            {cell.getValue()}
          </MDBBadge>
        ),
      },
    ],
    [eventStatuses]
  );

  const table = useMaterialReactTable({
    columns,
    // data: data?.data || [], // Använd data från React Query
    data: tableData, // Använd state för tabellens data
    localization: MRT_Localization_SV,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableContainerProps: { sx: { maxHeight: window.innerHeight * 0.65 } },
    enableGlobalFilter: true,
    enableStickyHeader: true,
    // columnFilterDisplayMode: "popover",
    manualPagination: true, // Aktivera manuell paginering
    manualSorting: true, // Aktivera manuell sortering
    manualFiltering: true, // Aktivera manuell filtrering
    rowCount: meta?.rowcount_total ?? 0,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    state: {
      columnFilters,
      globalFilter,
      sorting,
      pagination,
    },
    initialState: {
      sorting: [{ id: "start_date", desc: true }], // Standard sortering på start_date fallande
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    // paginationDisplayMode: "pages",
    // muiPaginationProps: {
    //   color: "secondary",
    //   rowsPerPageOptions: [10, 50, 100, 500],
    //   shape: "rounded",
    //   variant: "outlined",
    // },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderRowActionMenuItems: ({ closeMenu, row, table }) => [
      <MenuItem
        key="admin_event"
        //onClick={() => navigate(`/event/${row.original.key_id}`)}
      >
        <ListItemIcon>
          <MDBIcon fas icon="comment-slash" />
        </ListItemIcon>
        Test...
      </MenuItem>,
      row.original.status_id === "100" && (
        <MenuItem
          key="approve"
          onClick={(event) => {
            if (
              window.confirm("Är du säker på att du vill godkänna tävlingen?")
            ) {
              onLinkClick(row.original.key_id, "approve", 200);
              closeMenu();
            }
            event.stopPropagation(); // Prevent event bubbling
            event.target
              .closest(".MuiMenu-root")
              ?.dispatchEvent(new Event("close", { bubbles: true })); // Close the menu
          }}
        >
          <ListItemIcon>
            <MDBIcon fas icon="check-double" />
          </ListItemIcon>
          Godkänn tävlingen
        </MenuItem>
      ),
      row.original.status_id === "0" && (
        <MenuItem
          key="delete_draft"
          onClick={(event) => {
            if (
              window.confirm("Är du säker på att du vill godkänna tävlingen?")
            ) {
              onLinkClick(row.original.key_id, "approve_draft", 9999);
              closeMenu();
            }
            event.stopPropagation(); // Prevent event bubbling
            event.target
              .closest(".MuiMenu-root")
              ?.dispatchEvent(new Event("close", { bubbles: true })); // Close the menu
          }}
        >
          <ListItemIcon>
            <MDBIcon fas icon="trash-alt" />
          </ListItemIcon>
          Ta bort utkast
        </MenuItem>
      ),
    ],
    renderTopToolbarCustomActions: () => (
      <div className="d-flex flex-row mb-3">
        <MDBBtn
          onClick={() => navigate("/events/new-edit/")}
          size="sm"
          className="me-2"
        >
          <MDBIcon fas icon="plus" className="me-2" />
          Skapa tävling
        </MDBBtn>
        <MRT_ToggleFiltersButton table={table} />
      </div>
    ),
    renderToolbarInternalActions: () => (
      <>
        <MDBBtnGroup aria-label="Event Statuses" size="sm">
          {statues_groups.map((status) => (
            <MDBBtn
              key={status.id}
              // color={status.color}
              active={activeButton === status.id}
              onClick={() => {
                setActiveButton(status.id);
                setColumnFilters([]); // Nollställ alla kolumnfilter
              }}
            >
              {status.name}
            </MDBBtn>
          ))}
        </MDBBtnGroup>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag="a"
          size="sm"
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color="info" size="lg" />
        </MDBBtn>
      </>
    ),
    //   renderToolbarInternalActions: () => (
    //     <>
    // <div>
    //   <Button
    //     id="demo-positioned-button"
    //     aria-controls={open ? 'demo-positioned-menu' : undefined}
    //     aria-haspopup="true"
    //     aria-expanded={open ? 'true' : undefined}
    //     onClick={handleClick}
    //   >
    //     Status
    //   </Button>
    //   <Menu
    //     id="demo-positioned-menu"
    //     aria-labelledby="demo-positioned-button"
    //     anchorEl={anchorEl}
    //     open={open}
    //     onClose={handleClose}
    //     anchorOrigin={{
    //       vertical: 'top',
    //       horizontal: 'left',
    //     }}
    //     transformOrigin={{
    //       vertical: 'top',
    //       horizontal: 'left',
    //     }}
    //   >
    //     <MenuItem key={-1}
    //       onClick={() => {
    //         console.log("status.id", status.id);
    //         setActiveButton(-1);
    //         handleClose();
    //       }}
    //       >Alla</MenuItem>
    //     {statues_groups.map((status) => (
    //       <MenuItem key={status.id}
    //       onClick={() => {
    //         console.log("status.id", status.id);
    //         setActiveButton(status.id);
    //         handleClose();
    //       }}
    //       >{status.name}</MenuItem>
    //     ))}
    //   </Menu>
    // </div>

    //       <MRT_ToggleFullScreenButton table={table} />
    //     </>
    //   ),
  });

  return (
    <MDBContainer className="py-4">
      <h1>Tävlingar</h1>
      <MaterialReactTable table={table} />
      {isLoading && <p>Laddar...</p>}
      {isError && <p>Ett fel inträffade vid hämtning av data.</p>}
    </MDBContainer>
  );
}
