import React from "react";
import "./StepIndicator.css";

const WizardStepIndicator = ({
  currentStep,
  maxReachedStep,
  onNavigate,
  isEditMode,
  stepValidity,
  steps,
}) => {
  return (
    <div className="step-indicator">
      {steps.map((step, index) => {
        const isCurrent = currentStep === step.id;
        const isVisited = step.id <= maxReachedStep;
        const isFuture = step.id > maxReachedStep && !isEditMode;
        const isValid = stepValidity[step.id] === true;

        let className = "wizard-step-bullet";
        if (isCurrent) className += " current";
        else if (isVisited && isValid) className += " valid";
        else if (isVisited && !isValid) className += " invalid";
        else if (isFuture) className += " upcoming";

        return (
          <div key={step.id} className="step-wrapper text-center">
            <div
              className={className}
              onClick={() =>
                (isEditMode || step.id <= maxReachedStep) && onNavigate(step.id)
              }
              style={{
                cursor:
                  isEditMode || step.id <= maxReachedStep
                    ? "pointer"
                    : "default",
              }}
            >
              {step.id}
            </div>
            <div className="step-label d-none d-md-block">{step.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default WizardStepIndicator;
