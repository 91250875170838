import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import FileUpload from "./KansliFileUploadPayex";
import { fetch_get } from "../../../../services/common";
import LinkTable from "./KansliAccountingLinkTable";
import NotInPayex from "./KansliAccountingListNotInPayex";

import Payex_grp from "./KansliAccountingListPayexGrp";
import Ta_Payex from "./KansliAccountingListTaPayex";
import Ta_Payex_Detail from "./KansliAccountingListTaPayexDetail";
import LicPaymentMethod from "./KansliAccountingListLicPaymentMethod";
import LicSportgren from "./KansliAccountingListLicSportgren";
import LicSportgrenMore from "./KansliAccountingListLicSportgrenMore";
// 

export default function KansliAccounting() {
  const [link_data, setData_link] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    fetch_get("data/kansli_link_list")
      .then((data) => {
        setData_link(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleTabClick = (tab) => {
    if (tab === activeTab) return;
    setActiveTab(tab);
  };

  return (
    <MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
      <h3>Bokföringsunderlag</h3>
      <hr />
      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab1")}
            active={activeTab === "tab1"}
          >
            File Upload
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab2")}
            active={activeTab === "tab2"}
          >
            Link Table
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab3")}
            active={activeTab === "tab3"}
          >
            Not In Payex
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab4")}
            active={activeTab === "tab4"}
          >
            Payex
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab5")}
            active={activeTab === "tab5"}
          >
            TA -- Payex
          </MDBTabsLink>
        </MDBTabsItem> 
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab6")}
            active={activeTab === "tab6"}
          >
            TA -- Payex (Detaljerad)
          </MDBTabsLink>
        </MDBTabsItem> 
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab7")}
            active={activeTab === "tab7"}
          >
            LicPaymentMethod
          </MDBTabsLink>
        </MDBTabsItem>  
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab8")}
            active={activeTab === "tab8"}
          >
            Lic. Sportgren
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabClick("tab9")}
            active={activeTab === "tab9"}
          >
            Lic. Sportgren More
          </MDBTabsLink>
        </MDBTabsItem>                                         
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={activeTab === "tab1"}>
          <FileUpload />
        </MDBTabsPane>
        <MDBTabsPane open={activeTab === "tab2"}>
          <LinkTable loading={loading} link_data={link_data} />
        </MDBTabsPane>
        <MDBTabsPane open={activeTab === "tab3"}>
          <NotInPayex activeTab={activeTab} />
        </MDBTabsPane>
        <MDBTabsPane open={activeTab === "tab4"}>
          <Payex_grp activeTab={activeTab} />
        </MDBTabsPane>
        <MDBTabsPane open={activeTab === "tab5"}>
          <Ta_Payex activeTab={activeTab} />
        </MDBTabsPane>  
        <MDBTabsPane open={activeTab === "tab6"}>
          <Ta_Payex_Detail activeTab={activeTab} />
        </MDBTabsPane>     
        <MDBTabsPane open={activeTab === "tab7"}>
          <LicPaymentMethod activeTab={activeTab} />
        </MDBTabsPane>  
        <MDBTabsPane open={activeTab === "tab8"}>
          <LicSportgren activeTab={activeTab} />
        </MDBTabsPane>   
        <MDBTabsPane open={activeTab === "tab9"}>
          <LicSportgrenMore activeTab={activeTab} />
        </MDBTabsPane>                                      
      </MDBTabsContent>
      
    </MDBContainer>
  );
}
