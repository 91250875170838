import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import DatePicker from "components/common/DatePicker";
import RequiredLabel from "components/common/RequiredLabel";
import RequiredFieldsNote from "components/common/RequiredFieldsNote";
import EventWizardContentTitle from "../Components/EventWizardContentTitle";

const Step7_Payment = ({ formData, onChange, onChangeDate, stepTitle }) => {
  const max = formData.end_date
    ? new Date(new Date(formData.end_date).getTime() + 86400000)
    : undefined;

  return (
    <MDBRow className="d-flex justify-content-center">
      <MDBCol md="9" className="mb-3">
        <EventWizardContentTitle stepTitle={stepTitle} infoTextId={14} />
        <RequiredFieldsNote show={false} />

        <MDBValidationItem className="mb-3">
          <MDBInput
            name="betalning_kontonr"
            label={<RequiredLabel required={false}>Kontonummer</RequiredLabel>}
            value={formData.betalning_kontonr}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem className="mb-3">
          <MDBInput
            name="betalning_swish_nr"
            label={<RequiredLabel required={false}>Swish nummer</RequiredLabel>}
            value={formData.betalning_swish_nr}
            onChange={onChange}
          />
        </MDBValidationItem>

        <MDBValidationItem className="mb-3">
          <DatePicker
            inputLabel={
              <RequiredLabel required={false}>
                Sista Betalningsdatum
              </RequiredLabel>
            }
            value={formData.betalning_end_date}
            onChange={(val) => onChangeDate("betalning_end_date", val)}
            max={max}
          />
        </MDBValidationItem>

        <MDBValidationItem className="mb-3">
          <MDBTextArea
            name="betalning_information"
            label={
              <RequiredLabel required={false}>
                Betalningsinformation
              </RequiredLabel>
            }
            value={formData.betalning_information}
            onChange={onChange}
            rows={3}
          />
        </MDBValidationItem>
      </MDBCol>
    </MDBRow>
  );
};

export default Step7_Payment;
