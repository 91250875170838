import React, { useState, useEffect } from "react";
import { MDBBtn, MDBContainer, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import { useNavigationType } from "react-router-dom";
import { fetch_get } from "../../../services/common";
import EventAccordion from "./EventAccordion";
import EventsPageFilterModal from "./EventsPageFilterModal";
import "./EventsPage.css";

const EventsPage = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const [data, setData] = useState([]);
  const navigationType = useNavigationType();
  const [isLoading, setIsLoading] = useState(true);

  const [showFavorites, setShowFavorites] = useState(() => {
    return sessionStorage.getItem("showFavorites") === "true";
  });

  const [lovs, setLovs] = useState({
    kategorier: [],
    foreningar: [],
    distrikt: [],
    status: [],
    niva1: [],
    niva2: [],
    niva3: [],
    niva4: [],
  });

  const [filterValue, setFilterValue] = useState(() => {
    const storedFilter = sessionStorage.getItem("tmp_event_list_filter");
    return storedFilter
      ? JSON.parse(storedFilter)
      : {
          start_date: "",
          end_date: "",
          gren: [],
          kategori: "",
          forening: "",
          distrikt: "",
          status: "",
          niva1: "",
          niva2: "",
          niva3: "",
          niva4: "",
          typ: null,
          free_text: "",
        };
  });

  const getFormattedDate = (date) => date.toISOString().split("T")[0];

  const today = new Date();
  const sixMonthsLater = new Date();
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

  const defaultFilterValue = {
    start_date: getFormattedDate(today),
    end_date: getFormattedDate(sixMonthsLater),
    gren: [],
    kategori: "",
    forening: "",
    distrikt: "",
    status: "",
    niva1: "",
    niva2: "",
    niva3: "",
    niva4: "",
    typ: null,
    free_text: "",
  };

  useEffect(() => {
    sessionStorage.setItem(
      "tmp_event_list_filter",
      JSON.stringify(filterValue)
    );
  }, [filterValue]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [JSON.stringify(filterValue), showFavorites]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch_get(
        `app/list/events/?_kategori=${filterValue.kategori}&_forening=${
          filterValue.forening
        }&_distrikt=${filterValue.distrikt}&_status=${
          filterValue.status
        }&_niva1=${filterValue.niva1}&_niva2=${filterValue.niva2}&_niva3=${
          filterValue.niva3
        }&_niva4=${filterValue.niva4}&_typ=${filterValue.typ}&_start_date=${
          filterValue.start_date
        }&_end_date=${filterValue.end_date}&_free_text=${encodeURIComponent(
          filterValue.free_text
        )}${showFavorites ? "&_my_favorites=1" : ""}`
      );

      let filteredData = response.data || [];

      console.log("resp", response);

      // frontend filtering for free_text
      if (filterValue.free_text) {
        const searchTerm = filterValue.free_text.toLowerCase();
        filteredData = filteredData.filter(
          (event) =>
            event.name.toLowerCase().includes(searchTerm) ||
            (event.description &&
              event.description.toLowerCase().includes(searchTerm))
        );
      }

      setData(filteredData);

      const updatedLovs = {
        kategorier: [
          { value: "0", text: "Alla" },
          ...(response.lov?.kategorier || []),
        ],
        foreningar: [
          { value: "0", text: "Alla" },
          ...(response.lov?.foreningar || []),
        ],
        distrikt: [
          { value: "0", text: "Alla" },
          ...(response.lov?.distrikt || []),
        ],
        status: [{ value: "0", text: "Alla" }, ...(response.lov?.status || [])],
        niva1: [{ value: "0", text: "Alla" }, ...(response.lov?.niva1 || [])],
        niva2: [{ value: "0", text: "Alla" }, ...(response.lov?.niva2 || [])],
        niva3: [{ value: "0", text: "Alla" }, ...(response.lov?.niva3 || [])],
        niva4: [{ value: "0", text: "Alla" }, ...(response.lov?.niva4 || [])],
      };

      setLovs(updatedLovs);

      setFilterValue((prev) => {
        const newFilterValue = {
          ...prev,
          start_date: response.lov?.start_date || prev.start_date,
          end_date: response.lov?.end_date || prev.end_date,
        };

        if (JSON.stringify(newFilterValue) !== JSON.stringify(prev)) {
          return newFilterValue;
        }
        return prev;
      });

      sessionStorage.setItem("tmp_event_list", JSON.stringify(filteredData));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (navigationType === "PUSH") {
      setFilterValue(defaultFilterValue);
      setShowFavorites(false);
      sessionStorage.removeItem("tmp_event_list_filter");
      sessionStorage.removeItem("tmp_event_list_active");
      sessionStorage.removeItem("showFavorites");
    } else if (navigationType === "POP") {
      const storedFilter = sessionStorage.getItem("tmp_event_list_filter");
      if (storedFilter) {
        const parsedFilter = JSON.parse(storedFilter);
        if (JSON.stringify(parsedFilter) !== JSON.stringify(filterValue)) {
          setFilterValue(parsedFilter);
        }
      }
      const storedFavorites =
        sessionStorage.getItem("showFavorites") === "true";
      setShowFavorites(storedFavorites);
    }
  }, [navigationType]);

  useEffect(() => {
    sessionStorage.setItem(
      "tmp_event_list_filter",
      JSON.stringify(filterValue)
    );
  }, [filterValue]);

  useEffect(() => {
    sessionStorage.setItem("showFavorites", showFavorites);
  }, [showFavorites]);

  return (
    <MDBContainer className="py-4 events-page">
      <EventsPageFilterModal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        defaultFilterValue={defaultFilterValue}
        lovs={lovs}
      />

      <div className="d-flex flex-column gap-2 mb-3">
        <h1>Tävlingskalender</h1>
        <div className="d-flex justify-content-between">
          <MDBBtn
            className="w-auto event-button"
            onClick={() => setToggleModal((prev) => !prev)}
          >
            Filter
          </MDBBtn>

          <MDBTooltip
            tag="span"
            title={
              showFavorites
                ? "Visa alla evenemang"
                : "Filtrera på dina favoriter"
            }
          >
            <MDBBtn
              className={`w-auto event-button ${
                showFavorites
                  ? "bg-danger text-white"
                  : "bg-white border border-1 border-danger"
              }`}
              onClick={() => setShowFavorites((prev) => !prev)}
            >
              <MDBIcon
                fas
                icon="heart"
                className={showFavorites ? "text-white" : "text-danger"}
              />
            </MDBBtn>
          </MDBTooltip>
        </div>
      </div>

      <EventAccordion data={data} isLoading={isLoading} />
    </MDBContainer>
  );
};

export default EventsPage;
